import {
  Accordion,
  AccordionSummary,
  Tooltip
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useContext, useMemo, useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ConjuntoType, UnidadeType } from '../../service/Places'
import { ButtonsWrapper, PlacesTitleWrapper, PlacesWrapper } from './style';
import { AiFillCopy, AiFillDelete, AiFillTool } from 'react-icons/ai';
import UnityComponent from './UnityComponent';
import { UserContext } from '../../context/UserContext';
import Set from '../../service/Set';
import { toast } from 'react-toastify';
import AttToken from '../../helpers/attToken';
import { AxiosError } from 'axios';
import { useMutation } from '@tanstack/react-query';
import RemoveModal from './Modals/RemoveModal';
import EditModal from './Modals/EditModal';
import Unity from '../../service/Unity';
import NewModal from './Modals/NewModal';
import SetTypeAssoci from './Modals/AssociationsModals/SetTypeAssoci';
import { FaListAlt, FaNetworkWired } from 'react-icons/fa';
import EditAproveModal from './Modals/EditAproveModal';
import { BsShieldFillCheck } from 'react-icons/bs';
import { Label } from '../Notifications/style/SingleNotificationStyle';
import ModalPA from './Modals/modalPA/ModalPA';
import { reorderType } from './PlacesComponent';

const setService = new Set();

type setAcordeonType = {
  set: ConjuntoType,
  placeId: string,
  getPlaces: Function,
  selected: string[]
  conjuntosList: ConjuntoType[]
  index: number,
  expandedConjs: boolean[],
  setExpandedConjs: Function,
  local: reorderType,
  setLocal: Function
}

export default function SetAcordeon({
  set,
  placeId,
  getPlaces,
  selected,
  index,
  expandedConjs,
  setExpandedConjs,
  local,
  setLocal,
}:
  setAcordeonType
) {
  const [modalOpen, setModalOpen] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openRemove, setOpenRemove] = useState(false);
  const { hierarchyLabel, customerId, acessoPlanoDeAcao } = useContext(UserContext);
  const [associationModal, setAssociationModal] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [openModalAprove, setOpenModalAprove] = useState(false);
  const [reorderBy, setReorderBy] = useState<string>(local[set.id] || '');

  const [openModalPA, setOpenModalPA] = useState<boolean>(false)
  
  const UnityService = useMemo(() => new Unity(), []);

  const SetService = useMemo(() => new Set(), []);

  const { t } = useTranslation('translation');

  const { mutate } = useMutation({
    mutationKey: ['removeSet'],
    mutationFn: async () => {
      toast.warn(`${t('notifications.deleteNotifWait')}`)
      const data = await AttToken();
      if (data) {
        await setService.deleteConjunto(data, set.id, placeId);
        toast.success(`${hierarchyLabel.conjunto} ${t('deleted')}!`);
        setOpenRemove(false);
        getPlaces(customerId);
      }
    },
    onError: () => toast.error('Erro ao remover.')
  })

  const edit = async (name: string) => {
    setIsDisabled(true)
    try {
      const token = await AttToken();
      if (token) {
        await SetService.changeConjunto(token, set.id, name);
        setOpenEditModal(false);
        toast.success(`${hierarchyLabel.conjunto} ${t('edited')}!`);
        getPlaces(customerId);
      }
    } catch (err) {
      return console.log(err);
    }
  }

  const create = async (name: string) => {
    setIsDisabled(true)
    try {
      const token = await AttToken();
      const UnityObj = {
        cliente: customerId,
        conjunto: set.id,
        dataAlteracao: Date.now(),
        local: placeId,
        nome: name
      }
      if (token) {
        const response = await UnityService.newUnity(token, UnityObj)
        if(response) {
          toast.success(`${hierarchyLabel.unidade} ${t('created')}!`)
          setModalOpen(false);
          setIsDisabled(false);
          getPlaces(customerId);
        }
      }
    } catch (err) {
      return console.log(err);
    }
  }

  const switchSort = (value: string, a: UnidadeType, b: UnidadeType) => {
    switch (value) {
      case 'crescent':
        return (a.nome?.toLowerCase() > b.nome?.toLowerCase())
      case 'decrescent':
        return (a.nome?.toLowerCase() < b.nome?.toLowerCase())
      default: return (a.nome?.toLowerCase() > b.nome?.toLowerCase())
    }
  }

  const nameOf = 'conjunto';
  const nameCreate = 'unidade';
  const nameRemove = 'conjunto';

  const buttonStyle = {
    fontSize: '1.8em',
    padding: 0,
    border: '1px solid black'
  }

  return (
    <div style={{ margin: "10px 0", width: '95%' }}>
      {openModalPA && <ModalPA
        openModalPA={openModalPA}
        setOpenModalPA={setOpenModalPA}
        conjunto={set}
        getPlaces={getPlaces}
      />}
      {associationModal &&
        <SetTypeAssoci
          set={set}
          open={associationModal}
          setOpen={setAssociationModal}
          getPlaces={getPlaces}
        />}
      {openRemove &&
        <RemoveModal
          mutate={mutate}
          openRemove={openRemove}
          setOpenRemove={setOpenRemove}
          nameRemove={nameRemove}
        />}
      <Accordion expanded={expandedConjs[index]}>
        {modalOpen &&
          <NewModal
            isDisabled={isDisabled}
            nameCreate={nameCreate}
            create={create}
            setModalOpen={setModalOpen}
            modalOpen={modalOpen}
          />}
        {openEditModal &&
          <EditModal
            isDisabled={isDisabled}
            edit={edit}
            open={openEditModal}
            setOpen={setOpenEditModal}
            componentName={set.nome}
            nameOf={nameOf}
          />}
        {openModalAprove &&
          <EditAproveModal
            getPlaces={getPlaces}
            conjunto={set}
            openModalAprove={openModalAprove}
            setOpenModalAprove={setOpenModalAprove}
          />}
        <AccordionSummary
          expandIcon={<ExpandMoreIcon
            onClick={() => {
              expandedConjs.forEach((e, i) => {
                if (index === i) {
                  expandedConjs[i] = !expandedConjs[index]
                } else { expandedConjs[i] = false }
              })
              setExpandedConjs([...expandedConjs])
            }}
          />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ backgroundColor: '#f3f3f4' }}
        >
          <PlacesWrapper>
            <PlacesTitleWrapper>
              <h1>{set.nome}</h1>
            </PlacesTitleWrapper>
            <ButtonsWrapper>
              <Tooltip title={`${t('teamManage.register.kindOfService')}`}>
                <span>
                  <button
                    style={buttonStyle}
                    className='button is-small edit' onClick={() => {
                      setAssociationModal(true);
                    }}>
                    <FaNetworkWired size={25} />
                  </button>
                </span>
              </Tooltip>
              <Tooltip title={`${t('edit')} ${t('approver')}`}>
                <span>
                  <button
                    style={buttonStyle}
                    className='button is-small aprove'
                    onClick={() => setOpenModalAprove(true)}
                  >
                    <BsShieldFillCheck />
                  </button>
                </span>
              </Tooltip>
              <Tooltip title={`${t('edit')} ${hierarchyLabel.conjunto}`}>
                <span>
                  <button
                    className='button is-small is-light'
                    style={buttonStyle}
                    onClick={() => setOpenEditModal(true)}>
                    <AiFillTool size={25} />
                  </button>
                </span>
              </Tooltip>
              <Tooltip title={'Associações de Plano de Ação'}>
                <button
                  onClick={() => setOpenModalPA(!openModalPA)}
                  style={buttonStyle}
                  className='button is-small is-warning'
                  disabled={!acessoPlanoDeAcao}>
                  <FaListAlt />
                </button>
              </Tooltip>
              <Tooltip title={`${t('checklists.checklists.clone')} ${hierarchyLabel.conjunto}`}>
                <span>
                  <button
                    style={buttonStyle}
                    className='button is-small copy'
                    onClick={async () => {
                      toast.warn(`${t('places.Copiando_aguarde')}`)
                      try {
                        const token = await AttToken();
                        if (token) {
                          const response = await setService.duplicateConjunto(token, set.id);
                         if(response) {
                           getPlaces(customerId);
                           toast.success('Local duplicado!');
                         }
                        }
                      } catch (err) {
                        console.log(AxiosError)
                        toast.error('Erro ao duplicar!')
                      }
                    }}
                  >
                    <AiFillCopy size={25} />
                  </button>
                </span>
              </Tooltip>
              <Tooltip title={`${t('delete')} ${hierarchyLabel.conjunto}`}>
                <span>
                  <button
                    style={buttonStyle}
                    className='button is-small deletebtn' onClick={() => setOpenRemove(true)}>
                    <AiFillDelete size={25} />
                  </button>
                </span>
              </Tooltip>
            </ButtonsWrapper>
          </PlacesWrapper>
        </AccordionSummary>
        <div style={{ display: 'flex', width: '100%', flexDirection: 'column', backgroundColor: '#f3f3f4' }}>
          <button
            style={{ marginBottom: '10px', alignSelf: 'flex-start', marginLeft: '10px' }}
            className='button is-success'
            onClick={() => setModalOpen(true)}
          >
            {`${t('add')} ${hierarchyLabel.unidade} +`}
          </button>
          <Label style={{ marginLeft: '5px', fontSize: '15px' }}>
            {hierarchyLabel.unidade}:
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '15px' }}>
              <Label>Ordenar por:</Label>
              <select
                defaultValue={reorderBy}
                onChange={(event) => {
                  setReorderBy(event.target.value)
                  local[set.id] = event.target.value
                  setLocal(local)
                  localStorage.setItem('localOrder', JSON.stringify(local))
                }}
                style={{ margin: '0px 5px 0px 5px' }}
                className='select is-small'>
                <option value={'crescent'}>Alfabética crescente (A-Z)</option>
                <option value={'decrescent'}>Alfabética decrescente (Z-A)</option>
              </select>
            </div>
          </Label>
          {set.unidadesList && set.unidadesList.sort((unidadeA, unidadeB) => { return switchSort(reorderBy, unidadeA, unidadeB) ? 1 : - 1 }).map((e, i) => (
            <div
              style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              key={e.id}
            >
              <UnityComponent
                unity={e}
                getPlaces={getPlaces}
                selected={selected}
                set={set.id}
                unidadesList={set.unidadesList}
              />
            </div>
          ))}
        </div>
      </Accordion>
    </div>
  )
}
