import { Accordion, AccordionSummary } from '@material-ui/core';
import React, { useCallback, useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineCopy } from 'react-icons/ai';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AddBtn, TableContainer, WeightInput } from './FormStyle';
import { RiDeleteBin5Line } from 'react-icons/ri';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import FormSubItem from './FormSubItem';
import { StyledInputForm } from './Modals/FormModal/FormModalStyle';
import { DragDropContext, Droppable, Draggable, DropResult } from "@hello-pangea/dnd";
import { ChecklistType, ItemChecklist } from '../../service/Checklists';
import { UserContext, identifierTypesList } from '../../context/UserContext';

function FormItem({
  item,
  indexItem,
  setEditingForm,
  form,
  isEditing,
  expandedItem,
  handleChangeItem,
  setSubModalOpen,
  subModalOpen
}:
  {
    form: ChecklistType,
    item: ItemChecklist,
    indexItem: number,
    formId: string,
    setEditingForm: Function,
    setLoadingItem: Function,
    isEditing: boolean,
    expandedItem: string | boolean,
    handleChangeItem: Function,
    setSubModalOpen: Function,
    subModalOpen: boolean
  }) {

  const { t } = useTranslation('translation');
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [removing, setRemoving] = useState(false);

  const { identifiersByForm, setIdentifiersByForm } = useContext(UserContext);

  const onClickaddBtn = () => {
    const newSubitem = {
      avaliacao: [{ nome: '1', valor: 0 }, { nome: '2', valor: form.valorMaxScoring || 100 }],
      id: item?.subitens.length,
      itemid: item.id,
      nome: 'Subitem',
      tipo: 'avaliacao',
      peso: 1,
      threshold: -1
    }
    form.itens[indexItem].subitens = [...form.itens[indexItem].subitens, newSubitem];
    setEditingForm({ ...form })
  }

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const list = Array.from(item.subitens)
    const [reordered] = list.splice(result.source.index, 1)
    if (result.destination)
      list.splice(result.destination.index, 0, reordered)
    setEditingForm({ ...form, item: item.subitens })
    form.itens.forEach(it => it.subitens.forEach(sub => {
      if (sub.help && sub.help.foto){
        sub.help.newPhoto = true;
      }
    }))
    const obj = { ...form }
    obj.itens[indexItem].subitens = list
    setEditingForm(obj)
  }

  const duplicateItem = () => {
    const thisItem = { ...form.itens.filter((_e, i) => i === indexItem)[0] };
    thisItem.nome = `${thisItem.nome} (Cópia)`
    thisItem.id = `${form.id}|${form.versao}|${form.itens.length}`
    setEditingForm({
      ...form,
      itens: [...form.itens, thisItem]
    })
  };

  const removeItem = () => {
    setRemoving(true);
    if (item.subitens.some(sub => identifierTypesList.includes(sub.tipo))) {
      const listIdentiToRemove: string[] = [];
      item.subitens.forEach((sub, index) => {
        if (identifierTypesList.includes(sub.tipo)) {
          listIdentiToRemove.push(`${form.id}|${form.versao}|${indexItem}|${index}`);
        }
      })
      identifiersByForm.forEach(e => {
        if (e.formId === form.id) {
          e.identifiersList = e.identifiersList.filter(iden => !listIdentiToRemove.includes(iden.value))
        }
      })
      setIdentifiersByForm([...identifiersByForm])
    }
    setEditingForm({
      ...form,
      itens: form.itens.filter((_e, i) => i !== indexItem)
    })
    setConfirmationModal(false);
    setRemoving(false);
  }


  const TextInput = () => {
    const InputNameRef = useRef<HTMLInputElement>(null);
    const [name, setName] = useState(item.nome);
    const onChangeInput =
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
        item.nome = e.target.value;
      }

    return (
      <>
        <StyledInputForm
          type="text"
          onChange={onChangeInput}
          value={name}
          disabled={!isEditing}
          ref={InputNameRef}
          style={{ margin: 0, color: 'black', border: '1px solid black' }}
        />
      </>
    );
  }

  const WeightInputChange = () => {
    const InputWeightRef = useRef<HTMLInputElement>(null);
    const [weight, setWeight] = useState<number | undefined>(item?.peso ? Number(item.peso) : 0)

    const onChangeInput = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setWeight(Number(e.target.value));
        item.peso = Number(e.target.value);
      },
      []
    );

    return (
      <>
        {!isEditing ? <span style={{ border: '1px solid black', width: '40px', textAlign: 'center', marginRight: '5px' }}>{typeof item.peso === "number" ? item.peso : '-'}</span> :
          <WeightInput
            onKeyDown={(e) => { if (e.key === '-' || e.key === '+') e.preventDefault() }}
            type="number"
            inputMode='numeric'
            pattern='/^[0-9]*$/'
            onChange={onChangeInput}
            min={0}
            value={weight === 0 ? undefined : weight}
            disabled={!isEditing}
            ref={InputWeightRef}
            style={{ textAlign: 'center' }}
          />}
      </>
    );
  }


  return (
    <>
      {confirmationModal && (
        <ConfirmationModal
          yesFunction={removeItem}
          isOpen={confirmationModal}
          closeFunction={setConfirmationModal}
          removing={removing}
        />
      )}
      <Accordion
        expanded={expandedItem === item.id}>
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ width: '100%' }}
          expandIcon={<ExpandMoreIcon
            onClick={(isExpanded) => {
              if (expandedItem === item.id) {
                handleChangeItem(!isExpanded, '')
              } else
                handleChangeItem(isExpanded, item.id)
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }} />}
        >
          <div className='columns' style={{ width: '100%', display: 'flex' }}>
            <span
              style={{
                textAlign: 'center',
                margin: 0,
                padding: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              className='column is-1'>
              {`${indexItem + 1}`}
            </span>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: 0,
                padding: 0
              }}
              className='column'>
              <TextInput />
            </div>
            <div
              className='column is-one-quarter'
              style={{
                width: 'fit-content',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
              <p
                style={{ fontSize: '.8em' }}>
                {t('checklists.checklists.itemWeight')}
              </p>
              &nbsp;
              <WeightInputChange />
              <button
                style={{ margin: '.1em' }}
                className="button is-primary is-small"
                onClick={duplicateItem}
                disabled={!isEditing}
              >
                <AiOutlineCopy size={20} />
              </button>
              <button
                style={{ margin: '.1em' }}
                disabled={(form.itens.length <= 1 || !isEditing)}
                className="button is-danger is-small"
                onClick={() => {
                  setConfirmationModal(true);
                }}
              >
                <RiDeleteBin5Line size={20} />
              </button>
            </div>
          </div>
        </AccordionSummary>
        <AddBtn className="button is-success is-light" onClick={onClickaddBtn} disabled={!isEditing}>
          {t('checklists.checklists.addSubitem')}
        </AddBtn>
        <TableContainer className='a'>
          <table className="table is-bordered is-striped is-hoverable">
            <thead>
              <tr>
                <th align="center" style={{ width: "5vw" }}>{t('checklists.checklists.subItem')}</th>
                <th align="left" style={{ width: "60%" }}>{t('name')}</th>
                <th align="center" style={{ width: "12%" }}>{t('type')}</th>
                <th align="center" style={{ width: "5%" }}>{t('weight')}</th>
                <th align="center" style={{ width: "15%" }}>{t('options')}</th>
              </tr>
            </thead>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable isDropDisabled={!isEditing || subModalOpen} droppableId="subItem">
                {(provided) => (
                  <tbody className="subitems" {...provided.droppableProps} ref={provided.innerRef}>
                    {item.subitens?.map((sub, ind) => {
                      return (
                        <Draggable key={String(sub.id)} draggableId={String(sub.id)} index={ind} isDragDisabled={!isEditing || subModalOpen}>
                          {(provided) => (
                            <tr key={ind} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                              <FormSubItem
                                subitem={sub}
                                index={ind}
                                indexItem={indexItem}
                                isEditing={isEditing}
                                setEditingForm={setEditingForm}
                                form={form}
                                item={item}
                                setSubModalOpen={setSubModalOpen}
                              />
                            </tr>
                          )}
                        </Draggable>)
                    })}
                    {provided.placeholder}
                  </tbody>
                )}
              </Droppable>
            </DragDropContext>
          </table>
        </TableContainer>
      </Accordion>
    </>
  )
}

export default FormItem