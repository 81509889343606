import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { AiFillCopy, AiFillDelete, AiFillTool } from 'react-icons/ai'
import { toast } from 'react-toastify';
import { UserContext } from '../../context/UserContext';
import AttToken from '../../helpers/attToken';
import { UnidadeType } from '../../service/Places'
import { AuthBodyType } from '../../service/types';
import Unity from '../../service/Unity';
import { ButtonsWrapper, PlacesTitleWrapper, UnityWrapper } from './style';
import { AxiosError } from 'axios';
import { useMutation } from '@tanstack/react-query';
import RemoveModal from './Modals/RemoveModal';
import EditModal from './Modals/EditModal';
import { Tooltip } from '@material-ui/core';
import QrCodeModal from './Modals/QrCodeModal';

const UnityService = new Unity();

export default function UnityComponent({
  unity,
  getPlaces,
  set,
  unidadesList,
}
  : {
    unity: UnidadeType,
    getPlaces: Function,
    selected: string[],
    set: string,
    unidadesList: UnidadeType[],
  }) {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openRemove, setOpenRemove] = useState(false);
  const { hierarchyLabel, customerId } = useContext(UserContext);
  const [isDisabled, setIsDisabled] = useState(false);
  const { t } = useTranslation('translation');

  const { mutate } = useMutation({
    mutationKey: ['removeUnity'],
    mutationFn: async () => {
      toast.warn(`${t('notifications.deleteNotifWait')}`)
      const data = await AttToken();
      await UnityService.deleteUnity(data as AuthBodyType, unity.id, set);
      toast.success(`${hierarchyLabel.unidade} ${t('deleted')}!`);
      setOpenRemove(false);
      getPlaces(customerId);
    },
    onError: () => toast.error('Erro ao remover')
  })

  const edit = async (name: string) => {
    setIsDisabled(true)
    try {
      const token = await AttToken();
      if (token) {
        const response = await UnityService.editUnity(token, unity.id, name);
        if (response) {
          setOpenEditModal(false)
          toast.success(`${hierarchyLabel.unidade} ${t('edited')}!`);
          getPlaces(customerId);
        }
      }
    } catch (err) {
      return console.log(err);
    }
  }

  const nameOf = 'unidade';
  const nameRemove = 'unidade';

  const buttonStyle = {
    fontSize: '1.8em',
    padding: 0,
    border: '1px solid black'
  }

  return (
    <UnityWrapper style={{ width: '90%', marginLeft: '5%', marginBottom: '10px' }}>
      {openRemove &&
        <RemoveModal
          mutate={mutate}
          openRemove={openRemove}
          setOpenRemove={setOpenRemove}
          nameRemove={nameRemove}
        />}
      {openEditModal &&
        <EditModal
          isDisabled={isDisabled}
          edit={edit}
          open={openEditModal}
          setOpen={setOpenEditModal}
          componentName={unity.nome}
          nameOf={nameOf}
        />}
      <PlacesTitleWrapper style={{ width: '100%', justifyContent: 'center', marginLeft: '10px' }}>
        <h1>{unity.nome}</h1>
      </PlacesTitleWrapper>
      <ButtonsWrapper>
        <Tooltip title={`${t('edit')} ${hierarchyLabel.unidade}`}>
          <span>
            <button
              style={buttonStyle}
              className='button is-small is-light' onClick={() => setOpenEditModal(true)}>
              <AiFillTool size={25} />
            </button>
          </span>
        </Tooltip>
        <Tooltip title={`${t('checklists.checklists.clone')} ${hierarchyLabel.unidade}`}>
          <span>
            <button
              style={buttonStyle}
              onClick={async () => {
                toast.warn(`${t('places.Copiando_aguarde')}`)
                try {
                  const token = await AttToken()
                  if (token) {
                    const response = await UnityService.duplicateUnity(token, unity.id)
                    if (response) {
                      unidadesList.push(Object.values(response.updateUnidade)[0] as unknown as UnidadeType)
                      getPlaces(customerId);
                    }
                  }
                } catch (err) {
                  console.log(AxiosError)
                }
              }}
              className='button is-small copy'
            >
              <AiFillCopy size={25} />
            </button>
          </span>
        </Tooltip>
        <Tooltip title={`${t('delete')} ${hierarchyLabel.unidade}`}>
          <span>
            <button
              style={buttonStyle}
              className='button is-small deletebtn' onClick={() => setOpenRemove(true)}>
              <AiFillDelete size={25} />
            </button>
          </span>
        </Tooltip>
        <QrCodeModal unity={unity} />
      </ButtonsWrapper>
    </UnityWrapper>
  )
}
