import { PrintTemplateType } from '../Checklists/Types';
import { useCallback, useContext, useEffect, useState } from 'react';
import { FormExampleContainer } from '../Checklists/FormStyle';
import checkBrightness from '../../helpers/checkBrightness';
import { ChecklistReportType, ChecklistSubitemReport } from '../../service/Reports';
import { UserContext } from '../../context/UserContext';
import { useTranslation } from 'react-i18next';
import TicketListReportComponent from './TicketListReportComponent';
import RenderSwitch from './functions/RenderSwitch';
import MapModal from './MapModal';
import { LinkMapa } from './Style';
import { ChecklistSubitem } from '../../service/Checklists';
import { IoMdCheckmarkCircle } from "react-icons/io";
import { MdCancel } from "react-icons/md";

type DataDetailType = {
  printTemplate?: PrintTemplateType | undefined,
  selected?: ChecklistReportType,
  showHeaders?: boolean,
  showScoring?: boolean,
  findTeams?: Function,
  showHideFields?: boolean,
  camposOcultos?: Function,
  showNotSelected?: boolean,
  hideHierarchy: {
    cliente: boolean,
    local: boolean,
    conjunto: boolean,
    unidade: boolean
  },
  hideEquipe: boolean
  hideUser: boolean
  hideIdent: boolean
  hideAprov: boolean
  hideDate: { inicio: boolean, fim: boolean }

}

export type centerType = {
  lat: number,
  lng: number
}

export default function DataDetail({
  printTemplate,
  selected,
  showScoring,
  findTeams,
  camposOcultos,
  showNotSelected,
  showHeaders,
  showHideFields,
  hideHierarchy,
  hideEquipe,
  hideUser,
  hideIdent,
  hideAprov,
  hideDate,
}: DataDetailType) {
  const { hierarchyLabel, ticket } = useContext(UserContext);
  const { t } = useTranslation('translation');

  const [identificador, setIdentificador] = useState('')

  const getIdentificador = useCallback(() => {
    const novoIdentificador = selected?.identificador?.slice(0, -2)
    const index = selected?.identificador?.charAt(selected?.identificador?.length - 1)
    const item = selected?.itens?.find((ele) => ele.id === novoIdentificador)?.subitens?.find((ele) => ele.id === Number(index))
    if (item) {
      setIdentificador(String(item?.ocorrencia))
    } else setIdentificador('-')
  }, [selected?.identificador, selected?.itens])

  useEffect(() => {
    getIdentificador()
  }, [getIdentificador])

  const cliente = selected?.cliente ? selected.cliente : ticket?.cliente
  const conjunto = selected?.conjunto ? selected.conjunto : ticket?.conjunto
  const local = selected?.local ? selected.local : ticket?.local
  const unidade = selected?.unidade ? selected.unidade : ticket?.unidade
  const clienteHeader = hierarchyLabel.cliente
  const localHeader = hierarchyLabel.local
  const conjuntoHeader = hierarchyLabel.conjunto
  const unidadeHeader = hierarchyLabel.unidade
  //const agendamento = selected?.agendamento || t('checklists.printConfig.Nao_Consta')
  const agendamentoHeader = t('checklists.printConfig.Local_Realizado')
  const identificadorHeader = 'Identificador'
  //const identificador = selected?.identificador || t('checklists.printConfig.Nao_Consta')
  const teamHeader = t('team')
  const team = findTeams && findTeams(selected?.equipes).join(', ')
  const userHeader = t('profiles.user')
  const user = selected?.user || ''
  const inicioHeader = t('checklists.printConfig.Inicio')
  const inicio = new Date(selected?.inicio!!!).toLocaleString()
  const fimHeader = t('Reports.columns.end')
  const fim = new Date(selected?.fim!!!).toLocaleString()
  const aproveHeader = t('Reports.columns.aprooval')
  const aprovaal = selected?.aprovacao || t('checklists.printConfig.Nao_se_aplica');

  const [openMap, setOpenMap] = useState(false)

  const tipoExibido = (value: string) => {
    switch (value) {
      case 'avaliacao':
        return '(Avaliação)'
      case 'multiple':
        return '(Multiplo)'
      case 'unique':
        return '(Unico)'
      case 'textoCurto':
        return '(Texto curto)'
      case 'textoLongo':
        return '(Texto longo)'
      case 'list':
        return '(Lista de textos)'
      case 'email':
        return '(E-mail)'
      case 'placa':
        return '(Placa)'
      case 'cpfcnpj':
        return '(CPF / CNPJ)'
      case 'cep':
        return '(CEP)'
      case 'assinatura':
        return '(Assinatura)'
      case 'rg':
        return '(RG)'
      case 'data':
        return '(Data)'
      case 'hora':
        return '(Hora)'
      case 'telefone':
        return '(Telefone)'
      case 'numero':
        return '(Numero)'
      default:
        return (<div>AAAAAAA</div>)
    }
  }

  const center = {
    lat: Number(selected?.latitude) || 0,
    lng: Number(selected?.longitude) || 0
  }

  const verificaConformidade = (subItem: ChecklistSubitem) => {
    if (typeof subItem.threshold !== 'number') { subItem.threshold = -1 }
    let valido = true;
    if (typeof subItem.nota === 'number' && typeof subItem.threshold === 'number') {
      /* if (subItem.nota >= subItem.threshold + 1) valido = true;
      else valido = false; */
      if (subItem.nota <= subItem.threshold) {
        valido = false;
      }
    }
    return valido;
  };

  return (
    <>
      {openMap && <MapModal openMap={openMap} setOpenMap={setOpenMap} center={center} />}
      Legenda:
      <p>
        <div className="icon-text">
          <span className="icon has-text-success">
            <IoMdCheckmarkCircle className='is-success' />
          </span>
          <span>Avaliação em conformidade</span>
        </div>
      </p>
      <p>
        <div className="icon-text">
          <span className="icon has-text-success">
            <MdCancel className='icon has-text-danger is-small' />
          </span>
          <span>Avaliação em não conformidade</span>
        </div>
      </p>
      <table id="content-id" style={{ width: '100%' }}>
        <thead style={{ width: 'inherit' }}>
          {printTemplate &&
            <tr>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '5px 0px 5px 0px' }}>
                {printTemplate && printTemplate.logo && (
                  <img style={{}} alt="Logo da empresa" src={printTemplate && printTemplate.logo} width={130} />
                )}
              </div>
              <th
                style={{
                  backgroundColor: printTemplate && printTemplate.color,
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  padding: '10px',
                }}>
                <th
                  style={{
                    fontSize: `${printTemplate && printTemplate.headerText?.size}px`,
                    fontWeight: printTemplate && printTemplate.headerText?.bold ? 'bold' : 500,
                    fontStyle: printTemplate && printTemplate.headerText?.italic ? 'italic' : 'normal',
                    color: !printTemplate.color?.length ? 'black' : (checkBrightness(printTemplate?.color) ? 'black' : 'white')
                  }}
                >
                  {printTemplate && printTemplate.headerText?.text}
                </th>
              </th>
            </tr>
          }
        </thead>
        <FormExampleContainer>
          <tr style={{ width: '100%' }}>
            <th style={{
              wordBreak: 'break-all',
              fontFamily: 'sans-serif',
              fontSize: '1.5em'
            }}>{selected?.formulario}</th>
          </tr>
          {showHeaders && (
            <>
              <h3 style={{
                //fontFamily: 'sans-serif', 
                fontSize: '1.8em',
                textAlign: 'center'
              }}>{selected?.nomeEmpresa}</h3>
              <tr className='columns' style={{
                height: 'fit-content',
                margin: '10px 0px',
                width: '100%',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                flexWrap: 'wrap',
                padding: 0
              }}>
                <th className='column' style={{ width: '50%', alignItems: 'start', display: 'flex', flexDirection: 'column' }}>
                  {hideHierarchy.cliente && <p style={{ fontSize: '14px' }}>{clienteHeader}: <span style={{ fontWeight: 500 }}>{cliente}</span></p>}
                  {hideHierarchy.local && <p style={{ fontSize: '14px' }}>{localHeader}: <span style={{ fontWeight: 500 }}>{local}</span></p>}
                  {hideHierarchy.conjunto && <p style={{ fontSize: '14px' }}>{conjuntoHeader}: <span style={{ fontWeight: 500 }}>{conjunto}</span></p>}
                  {hideHierarchy.unidade && <p style={{ fontSize: '14px' }}>{unidadeHeader}: <span style={{ fontWeight: 500 }}>{unidade}</span></p>}
                  {selected?.latitude && typeof selected.latitude === 'number' && <p style={{ fontSize: '14px' }}>{agendamentoHeader}: <LinkMapa onClick={() => setOpenMap(!openMap)}>Ver mapa</LinkMapa></p>}
                  {hideIdent && <p style={{ fontSize: '14px' }}>{identificadorHeader}: <span style={{ fontWeight: 500 }}>{window.location.pathname !== '/aprovacao' ? selected?.identificador : identificador}</span></p>}
                  {showScoring && <p style={{ fontSize: '14px' }}>{`Scoring: ${selected?.scorePorcentagem || '-'}`}</p>}
                </th>
                <th className='column' style={{ width: '50%', alignItems: 'start', display: 'flex', flexDirection: 'column', wordBreak: 'break-word', wordWrap: 'normal' }}>
                  {hideEquipe && <p style={{ fontSize: '14px' }}>{teamHeader}: <span style={{ fontWeight: 500 }}>{team}</span></p>}
                  {hideUser && <p style={{ fontSize: '14px' }}>{userHeader}: <span style={{ fontWeight: 500 }}>{user}</span></p>}
                  {hideDate.inicio && <p style={{ fontSize: '14px' }}>{inicioHeader}: <span style={{ fontWeight: 500 }}>{inicio}</span></p>}
                  {hideDate.fim && <p style={{ fontSize: '14px' }}>{fimHeader}: <span style={{ fontWeight: 500 }}>{fim}</span></p>}
                  {hideAprov && <p style={{ fontSize: '14px' }}>{aproveHeader}: <span style={{ fontWeight: 500 }}>{`${aprovaal}`}</span></p>}
                  {showScoring && <p style={{ fontSize: '14px' }}>{`Range: ${selected?.range || '-'}`}</p>}
                </th>
              </tr>
              {window.location.pathname === '/preenchimento' &&
                showHideFields &&
                selected?.camposOcultos && camposOcultos && camposOcultos(selected?.camposOcultos)
              }
            </>
          )}
          {selected?.itens?.map((e, itemIndex) => {
            return !e.subitens?.some(sub => sub.checked) ? <></> : (<tr key={itemIndex}>
              <h1 key={itemIndex} style={{
                fontFamily: 'Arial',
                fontSize: '1.4em'
              }}>{
                  showScoring ?
                    <>
                      <li style={{ color: 'gray' }}>
                        <span style={{ textDecoration: 'underline' }}>
                          {e.nome}
                        </span>
                        <span style={{ fontWeight: '100' }}>
                          &nbsp;- Scoring: {selected?.itens[itemIndex].scorePorcentagem}
                        </span>
                      </li>
                    </> :
                    <span
                      style={{ textDecoration: 'underline', color: 'gray' }}>
                      {e.nome}
                    </span>
                }
              </h1>
              {e.subitens?.map((ele, subIndex) => {
                return !ele.checked ? <></> : (
                  <ul>
                    <h3 style={{ fontSize: '1em', fontWeight: 600, color: 'gray' }}>
                      <div style={{ display: 'flex' }}>
                        <span style={{ fontWeight: 500 }}>
                          ({subIndex + 1})
                        </span>&nbsp;
                        - {ele.nome}
                        {
                          ele.tipo === 'avaliacao' && verificaConformidade(ele) ?
                            (
                              <div className="icon-text">
                                <span className="icon has-text-success">
                                  <IoMdCheckmarkCircle className='is-success' />
                                </span>
                              </div>
                            ) : ele.tipo === 'avaliacao' && !verificaConformidade(ele) && (
                              <div className="icon-text">
                                <span className="icon has-text-success">
                                  <MdCancel className='icon has-text-danger is-small' />
                                </span>
                              </div>)}
                      </div>
                      <p style={{ color: 'gray', fontWeight: '300' }}>{tipoExibido(ele.tipo)}</p>
                    </h3>
                    {/* <p className='typeP'>{`(${optionsSelectMap[ele.tipo as keyof typeof optionsSelectMap]})`}</p> */}
                    {RenderSwitch(ele as ChecklistSubitemReport, showNotSelected!!!, selected)}
                    {
                      showScoring &&
                      <span style={{ color: 'gray', fontWeight: 'bold' }}>Scoring: {selected?.itens[itemIndex]?.subitens[subIndex]?.scorePorcentagem}</span>}
                    {ele.chamadosConfig && (
                      <>
                        {ele.chamadosConfig &&
                          <TicketListReportComponent
                            addingTickets={{
                              itemIndex,
                              subItemIndex: subIndex,
                              active: true
                            }}
                            chamadosObj={ele.chamados}
                            chamadosConfig={ele.chamadosConfig}
                            formId={selected.idForm ? selected.idForm : selected.id || '-'}
                            inicio={Number(selected.inicio)}
                            commingFromSub={true}
                            chamadosConfigSub={ele.chamadosConfig}
                            selected={selected}
                          />}
                      </>
                    )}
                  </ul>
                )
              })}
            </tr>
            )
          })}
        </FormExampleContainer>
        <tfoot>
          {printTemplate &&
            <tr>
              <th style={{
                backgroundColor: printTemplate && printTemplate.color,
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                padding: '10px',
              }}>
                <th
                  style={{
                    fontSize: `${printTemplate && printTemplate?.footerText?.size}px`,
                    fontWeight: printTemplate && printTemplate?.footerText?.bold ? 'bold' : 500,
                    fontStyle: printTemplate && printTemplate?.footerText?.italic ? 'italic' : 'normal',
                    color: (!printTemplate?.color ? 'black' : printTemplate?.color && checkBrightness(printTemplate?.color) ? 'black' : 'white')
                  }}
                >
                  {printTemplate && printTemplate?.footerText?.text}
                </th>
              </th>
            </tr>
          }
        </tfoot>
      </table>
      <hr />
    </>
  )
}