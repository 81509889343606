import React, { useContext, useEffect, useMemo, useState } from 'react'
import { FaBold, FaItalic } from 'react-icons/fa';
import { UserContext } from '../../../context/UserContext'
import { Input, Label } from '../../../StyledComponents/Input/generic';
import { GenericHeader, ModalCard, ModalCardbody } from '../../../StyledComponents/Modal/generic';
import { CheckboxContainer, DropzoneContainer, PreviewColor, PrintTemplateModalContainer, ResizeContainer } from '../FormStyle';
import { ButtonsContainer } from './FormModal/FormModalStyle';
import { ColorResult, SketchPicker } from 'react-color';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import Clients, { ClientType } from '../../../service/Clients';
import AttToken from '../../../helpers/attToken';
import PrintService from '../../../service/PrintTemplate';
import { PrintTemplateType } from '../Types';
import { toast } from 'react-toastify';
import PrintPreviewCreateModal from './PrintPreviewCreateModal';
import queryClient from '../../../service/query';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { Autocomplete, Skeleton, TextField } from '@mui/material';
import { userBackObj } from '../../Users/UserSearch';

export default function PrintTemplateModal({ editing, setEditing, selectedPrint }
  : { editing: boolean, setEditing: Function, selectedPrint: PrintTemplateType }) {

  const { openModal, popUp, setPopUp, hierarchyLabel } = useContext(UserContext);
  const customerService = useMemo(() => new Clients(), []);
  const printService = useMemo(() => new PrintService(), []);
  const { t } = useTranslation('translation');
  const [printPreviewModal, setPrintPreviewModal] = useState(false);
  const [showCustomer, setShowCustomer] = useState(false);
  const [showHeader, setShowHeader] = useState(false);
  const [showFooter, setShowFooter] = useState(false);
  const [showColor, setShowColor] = useState(false);
  const [canShow, setCanShow] = useState(true);

  const [printTemplate, setPrintTemplate] = useState<PrintTemplateType>({
    name: 'Novo template',
    dafaultTemplate: false,
    cliente: undefined,
    headerText: {
      bold: false,
      italic: false,
      size: 10,
      text: undefined,
    },
    footerText: {
      bold: false,
      italic: false,
      size: 10,
      text: undefined,
    },
    color: undefined,
    logo: undefined,
    status: true,
  } as PrintTemplateType);

  useEffect(() => {
    if (editing && selectedPrint) {
      selectedPrint.cliente?.length && setShowCustomer(true);
      selectedPrint.color?.length && setShowColor(true);
      if (selectedPrint.headerText?.bold
        || selectedPrint.headerText?.italic
        || selectedPrint.headerText?.size
        || selectedPrint.headerText?.text) {
        setShowHeader(true);
      }
      if (selectedPrint.footerText?.bold
        || selectedPrint.footerText?.italic
        || selectedPrint.footerText?.size
        || selectedPrint.footerText?.text) {
        setShowFooter(true);
      }
      setPrintTemplate({ ...selectedPrint })
    }
  }, [])

  const { data, isLoading } = useQuery({
    queryKey: ['GET_CLIENTES'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const body = { ...token, empresa: token.userEmpresa };
        const result = await customerService.getAllClients(body, true);
        return result.clientList
      }
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  })

  function getBase64(file: File, cb: (s: string) => void) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      cb(reader.result as string)
    }
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  const renderImages = (File: FileList | null) => {
    if (File) {
      Array.from(File).map(e => getBase64(e, (i) => setPrintTemplate({ ...printTemplate, logo: i })));
    }
  }

  const { mutate: create, isLoading: loadingCreate } = useMutation({
    mutationKey: ['CREATE_TEMPLATE'],
    mutationFn: async () => {
      const token = await AttToken();
      if (token) {
        await printService.createPrintTemplate(token, { ...printTemplate, logo: printTemplate.logo || null })
      }
    },
    onSuccess: () => {
      openModal();
      setPrintTemplate({
        name: 'Novo template',
        dafaultTemplate: false,
        cliente: undefined,
        headerText: {
          bold: false,
          italic: false,
          size: 10,
          text: undefined,
        },
        footerText: {
          bold: false,
          italic: false,
          size: 10,
          text: undefined,
        },
        color: undefined,
        logo: undefined,
        status: true,
      })
      queryClient.resetQueries(['GET_ALL_PRINTS'])
      toast.success('Template criado');
    }
  })

  const { mutate: edit, isLoading: loadingEdit } = useMutation({
    mutationKey: ['EDIT_TEMPLATE'],
    mutationFn: async () => {
      toast.warn('Salvando edição')
      const token = await AttToken();
      if (token) {
        await printService.editPrintTemplate(token, { ...printTemplate, logo: printTemplate.logo || undefined, empresa: undefined }, selectedPrint.id as string);
      }
    },
    onSuccess: () => {
      queryClient.resetQueries(['GET_ALL_PRINTS'])
      openModal();
      setPrintTemplate({
        name: 'Novo template',
        dafaultTemplate: false,
        cliente: undefined,
        headerText: {
          bold: false,
          italic: false,
          size: 10,
          text: undefined,
        },
        footerText: {
          bold: false,
          italic: false,
          size: 10,
          text: undefined,
        },
        color: undefined,
        logo: undefined,
        status: true,
      })
      setEditing(false);
      toast.success('Template editado');
    }
  })

  return (
    <>
      {printPreviewModal && !canShow &&
        <PrintPreviewCreateModal
          printTemplate={printTemplate}
          image={String(printTemplate?.logo)}
          printPreviewModal={printPreviewModal}
          setPrintPreviewModal={setPrintPreviewModal}
          setCanShow={setCanShow}
        />}
      {canShow && <div className={`modal ${popUp ? "modal is-active" : "modal"}`}>
        <div className="modal-background" onClick={() => {
          setCanShow(true);
          setPopUp(false);
        }} />
        <ModalCard className="modal-card" onSubmit={(e: React.FormEvent) => e.preventDefault()}>
          <header className="modal-card-head" style={{ display: "flex", flexDirection: "column" }}>
            <GenericHeader>
              {t('checklists.printConfig.configPrintTemplate')}
            </GenericHeader>
          </header>
          <ModalCardbody className="modal-card-body">
            <PrintTemplateModalContainer>
              <Label>{t('checklists.printConfig.templateName')}
                <Input
                  className='input is-fullwidth is-small'
                  value={printTemplate.name}
                  onChange={(e) => {
                    setPrintTemplate({ ...printTemplate, name: e.target.value })
                  }}
                />
              </Label>
              <CheckboxContainer>
                <Label className='checkbox'>
                  <input
                    type="checkbox"
                    checked={printTemplate.dafaultTemplate}
                    onChange={() => {
                      setPrintTemplate({ ...printTemplate, dafaultTemplate: !printTemplate.dafaultTemplate });
                    }}
                  />
                  {t('checklists.printConfig.setDefault')}
                </Label>
              </CheckboxContainer>
              <CheckboxContainer>
                <Label className='checkbox'>
                  <input
                    type="checkbox"
                    checked={showCustomer}
                    onChange={(e) => {
                      if (!e.target.checked) {
                        delete selectedPrint.cliente
                        setPrintTemplate({ ...selectedPrint })
                      }
                      setShowCustomer(e.target.checked);
                    }}
                  />
                  {t('checklists.printConfig.customerLimit')}
                </Label>
                {
                  showCustomer && (
                    isLoading ? <Skeleton variant='rounded' height={40} /> :
                      <Autocomplete
                        value={printTemplate.cliente ? {
                          value: printTemplate?.cliente,
                          label: data?.find((ele: ClientType) => ele.id === printTemplate?.cliente)?.nome
                        } : null}
                        id="combo-box-demo"
                        sx={{ marginTop: '10px' }}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        filterSelectedOptions
                        options={
                          data
                            ?.sort((a: ClientType, b: ClientType) => { return a.nome > b.nome ? 1 : - 1 })
                            ?.filter((e: ClientType) => e.id !== '')
                            ?.map((ele: any) => ({
                              label: ele.nome,
                              value: ele.id,
                            }))}
                        onChange={(event: any, newValue: { label: string, value: string } | null) => {
                          setPrintTemplate({ ...printTemplate, cliente: newValue?.value })
                        }}
                        renderOption={(props, option, { selected }) => (
                          <li {...props} key={option.value}>
                            <span>{option.label}</span>
                          </li>)}
                        renderInput={(params) => <TextField {...params} label={hierarchyLabel.cliente} />}
                      />
                  )}
              </CheckboxContainer>
              <CheckboxContainer>
                <Label className='checkbox'>
                  <input
                    type="checkbox"
                    checked={showHeader}
                    onChange={(event) => {
                      if (!event.target.checked) {
                        delete selectedPrint.headerText
                        setPrintTemplate({ ...selectedPrint })
                      }
                      setShowHeader(event.target.checked);
                    }}
                  />
                  {t('checklists.printConfig.headerText')}
                </Label>
                {
                  showHeader && (
                    <>
                      <textarea
                        className='textarea is-small is-success'
                        rows={2}
                        value={printTemplate.headerText?.text}
                        onChange={(e) => {
                          setPrintTemplate({
                            ...printTemplate,
                            headerText: {
                              ...printTemplate.headerText,
                              text: e.target.value
                            }
                          })
                        }}
                        style={{
                          fontSize: printTemplate.headerText?.size,
                          fontWeight: printTemplate.headerText?.bold ? 'bold' : 500,
                          fontStyle: printTemplate.headerText?.italic ? 'italic' : 'normal'
                        }}
                      />
                      <ResizeContainer>
                        <span>{t('checklists.printConfig.fontSize')}</span>
                        <Input
                          className='input is-small'
                          type="number"
                          onChange={(e) => {
                            setPrintTemplate({
                              ...printTemplate,
                              headerText: {
                                ...printTemplate.headerText,
                                size: Number(e.target.value)
                              }
                            })
                          }}
                          value={printTemplate.headerText?.size}
                        />
                        <button
                          className={printTemplate.headerText?.bold ? 'marked' : ''}
                          onClick={() => {
                            setPrintTemplate({
                              ...printTemplate,
                              headerText: {
                                ...printTemplate.headerText,
                                bold: !printTemplate.headerText?.bold
                              }
                            })
                          }}
                        >
                          <FaBold />
                        </button>
                        <button
                          className={printTemplate.headerText?.italic ? 'marked' : ''}
                          onClick={() => {
                            setPrintTemplate({
                              ...printTemplate,
                              headerText: {
                                ...printTemplate.headerText,
                                italic: !printTemplate.headerText?.italic
                              }
                            })
                          }}
                        >
                          <FaItalic />
                        </button>
                      </ResizeContainer>
                    </>
                  )}
              </CheckboxContainer>
              <CheckboxContainer>
                <Label className='checkbox'>
                  <input
                    type="checkbox"
                    checked={showFooter}
                    onChange={(event) => {
                      if (!event.target.checked) {
                        delete selectedPrint.footerText;
                        setPrintTemplate({ ...selectedPrint })
                      }
                      setShowFooter(event.target.checked);
                    }}
                  />
                  {t('checklists.printConfig.footerText')}
                </Label>
                {showFooter && (
                  <>
                    <textarea
                      className='textarea is-small is-success'
                      rows={2}
                      value={printTemplate.footerText?.text}
                      onChange={(e) => {
                        setPrintTemplate({
                          ...printTemplate,
                          footerText: {
                            ...printTemplate.footerText,
                            text: e.target.value
                          }
                        })
                      }}
                      style={{
                        fontSize: printTemplate.footerText?.size,
                        fontWeight: printTemplate.footerText?.bold ? 'bold' : 500,
                        fontStyle: printTemplate.footerText?.italic ? 'italic' : 'normal'
                      }}
                    />
                    <ResizeContainer>
                      <span>{t('checklists.printConfig.fontSize')}</span>
                      <Input
                        className='input is-small'
                        type="number"
                        onChange={(e) => {
                          setPrintTemplate({
                            ...printTemplate,
                            footerText: {
                              ...printTemplate.footerText,
                              size: Number(e.target.value)
                            }
                          })
                        }}
                        value={printTemplate.footerText?.size}
                      />
                      <button
                        className={printTemplate.footerText?.bold ? 'marked' : ''}
                        onClick={() => {
                          setPrintTemplate({
                            ...printTemplate,
                            footerText: {
                              ...printTemplate.footerText,
                              bold: !printTemplate.footerText?.bold
                            }
                          })
                        }}
                      >
                        <FaBold />
                      </button>
                      <button
                        className={printTemplate.footerText?.italic ? 'marked' : ''}
                        onClick={() => {
                          setPrintTemplate({
                            ...printTemplate,
                            footerText: {
                              ...printTemplate.footerText,
                              italic: !printTemplate.footerText?.italic
                            }
                          })
                        }}
                      >
                        <FaItalic />
                      </button>
                    </ResizeContainer>
                  </>
                )}
              </CheckboxContainer>
              <CheckboxContainer>
                <Label className='checkbox'>
                  <input
                    type="checkbox"
                    checked={showColor}
                    onChange={(event) => {
                      if (!event.target.checked) {
                        selectedPrint.color = undefined
                        selectedPrint.logo = undefined
                        setPrintTemplate({ ...selectedPrint })
                      }
                      setShowColor(event.target.checked);
                    }}
                  />
                  {t('checklists.printConfig.graphicSettings')}
                </Label>
                {showColor && (
                  <div style={{ display: 'flex', marginTop: '20px', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '220px', justifyContent: 'center' }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ fontSize: '15px', fontWeight: 'bold' }}>{t('checklists.printConfig.backgroundColor')}</span>
                        <span>
                          <PreviewColor
                            style={{ backgroundColor: printTemplate?.color }}
                            onClick={() => setPrintTemplate({ ...printTemplate, color: printTemplate?.color })}
                          />
                        </span>
                      </div>
                      {showColor && (
                        <SketchPicker
                          color={printTemplate.color}
                          onChangeComplete={
                            (color: ColorResult) => {
                              setPrintTemplate({ ...printTemplate, color: color.hex })
                            }
                          }
                          disableAlpha
                          presetColors={['#fff', '#000', '#f00', '#0f0', '#00f']}
                        />
                      )}
                    </div>
                    <DropzoneContainer>
                      <span>{t('checklists.printConfig.headerPicture')}</span>
                      {printTemplate.logo && <img alt="Logo da empresa" src={printTemplate.logo} width={'95%'} />}
                      <span className='button is-success'>
                        <input
                          accept="image/png, image/jpeg"
                          value={undefined}
                          id="img"
                          name="img"
                          multiple={true}
                          className="file-input"
                          type="file"
                          onChange={(e) => renderImages(e.target.files)}
                        />
                        {t('users.userRegister.selectMessage')}
                        <AddPhotoAlternateIcon />
                      </span>
                    </DropzoneContainer>
                  </div>
                )}
              </CheckboxContainer>
            </PrintTemplateModalContainer>
            <Label>{t('status')}</Label>
            <select
              className='select is-small'
              onChange={(e) => setPrintTemplate({ ...printTemplate, status: e.target.value === 'ativo' ? true : false })}
              defaultValue={printTemplate.status ? 'ativo' : 'inativo'}
            >
              <option value={'ativo'}>Ativo</option>
              <option value={'inativo'}>Inativo</option>
            </select>
          </ModalCardbody>
          <footer className="modal-card-foot">
            <ButtonsContainer>
              <button
                className='button is-light is-small'
                onClick={() => {
                  setPrintPreviewModal(true);
                  setCanShow(false);
                }}
                style={{ marginRight: '300px' }}
              >
                {t('checklists.printConfig.preview')}
              </button>
              <button disabled={loadingCreate || loadingEdit}
                className='button is-success is-small'
                onClick={!editing ? () => create() : () => edit()}>{t('save')}</button>
              <button className='button is-warning is-small' onClick={() => {
                openModal()
                setEditing(false);
                setPrintTemplate({
                  name: 'Novo template',
                  dafaultTemplate: false,
                  cliente: undefined,
                  headerText: {
                    bold: false,
                    italic: false,
                    size: 10,
                    text: undefined,
                  },
                  footerText: {
                    bold: false,
                    italic: false,
                    size: 10,
                    text: undefined,
                  },
                  color: ' #FFF',
                  logo: undefined,
                  status: true,
                })
              }}>{t('cancel')}</button>
            </ButtonsContainer>
          </footer>
        </ModalCard>
      </div >}
    </>
  )
}
