import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { AiOutlineClose } from 'react-icons/ai';
import ReactInputMask from 'react-input-mask';
import { UserContext } from '../../context/UserContext';
import { GenericHeader, TagContainer, TagSpan } from '../../StyledComponents/Modal/generic';
import { Label, Input } from "../../StyledComponents/Input/generic";
import Loading from '../Loading/Loading';
import Clients, { ClientType } from "../../service/Clients";
import { ToastContainer, toast } from "react-toastify";
import AttToken from '../../helpers/attToken';
import queryClient from '../../service/query';
import { userTag } from '../Users/TagControll';
import { Autocomplete, TextField } from '@mui/material';

export type TagType = {
  id: string,
  name: string,
}

export default function ChangeCustomerModal({ selectedCustomer, customers, customerTags }: { selectedCustomer: string, customers: ClientType[], customerTags: userTag[] }) {


  const { openModal, popUp, escape } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [alteredCustomer, setAlteredCustomer] = useState<ClientType>({
    id: '',
    contato: '',
    dataCadastro: 0,
    empresa: ' ',
    foto: ' ',
    listaGerencia: [],
    nome: '',
    responsavel: ' ',
    status: '',
    locais: [],
    tags: [],
    tiposServico: []
   })

  const { t } = useTranslation('translation');

  const customerService = useMemo(() => new Clients(), []);

  const updateCustomer = useCallback(async () => {
    try {
      toast.warn('Salvando alterações')
      const token = await AttToken();
      if (token) {
        await customerService.updateClient({ ...token }, alteredCustomer);
        queryClient.resetQueries(['GET_CUSTOMERS'])
        openModal();
        toast.success(t<string>(`customerPage.toastChangeCustomer`));
      }
    }
    catch (err) {
      return console.log(err);
    }
  }, [alteredCustomer, openModal, customerService, t])

  useEffect(() => {
    if (loading && customers.length > 0) {
      const { nome, contato, status, id, tags, dataCadastro, empresa, tiposServico, locais, listaGerencia } = customers.filter(e => e.id === selectedCustomer)[0];
      setAlteredCustomer({
        ...alteredCustomer,
        id,
        nome,
        contato,
        status,
        tags: tags ? tags as string[] : [] as string[],
        empresa,
        dataCadastro,
        tiposServico,
        locais,
        listaGerencia
      })
      setLoading(false);
    }
  }, [loading, selectedCustomer, customers, alteredCustomer]);

  const findTagName = (tagId: string) => {
    const tag = customerTags.find((el: TagType) => el.id === tagId);
    if (tag) return tag.name
    else return 'teste';
  }

  return loading ? (<Loading />) : (
    <div className={`modal ${popUp ? "modal is-active" : "modal"}`}>
      <ToastContainer />
      <div className="modal-background" onKeyDown={() => escape()} onClick={() => openModal()} />
      <div className="modal-card" onSubmit={(e: React.FormEvent) => e.preventDefault()}>
        <header className="modal-card-head" style={{ display: "flex", flexDirection: "column" }}>
          <GenericHeader>
            {t('customerPage.AlterarCliente')}
          </GenericHeader>
        </header>
        <div className="modal-card-body">
          <div
            style={{
              display: "flex",
              flexDirection: "column"
            }}
            onSubmit={(e) => e.preventDefault()}
          >
            <Label>{t('name')}</Label>
            <Input
              maxLength={100}
              className="input is-small"
              type="text"
              value={alteredCustomer.nome}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAlteredCustomer({ ...alteredCustomer, nome: e.target.value })}
            />
            <Label>{t('tel')}</Label>
            <ReactInputMask
              mask="(99) 9999-99999"
              className="input is-small"
              type="tel"
              maskChar=""
              value={alteredCustomer.contato}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAlteredCustomer({ ...alteredCustomer, contato: e.target.value })}
            />
            <Autocomplete
              multiple
              value={alteredCustomer.tags?.map((ele) => (
                {
                  value: ele,
                  label: customerTags.find((elem)=> elem.id === ele)?.name
                }
              )) || undefined}
              id="combo-box-demo"
              sx={{ marginTop: '10px' }}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              filterSelectedOptions
              noOptionsText={'Sem tags disponiveis'}
              options={
                customerTags
                ?.sort((a, b) => { return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : - 1 })
                ?.map((ele: any) => ({
                    label: ele.name,
                    value: ele.id,
                  }))}
                  onChange={(event: any, newValue: { label: string, value: string }[] | null, reason: any, details: any) => {
                const value = details?.option.value;
                if (alteredCustomer.tags?.includes(value)) {
                  setAlteredCustomer({ ...alteredCustomer, tags: alteredCustomer.tags ? alteredCustomer.tags.filter((el) => el !== value) : []})
                } else {
                  setAlteredCustomer({ ...alteredCustomer, tags: alteredCustomer.tags ? [...alteredCustomer.tags, value] : [value] })
                }
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option.value}>
                  <span>{option.label}</span>
                </li>)}
              renderInput={(params) => <TextField {...params} label={t('tag')} />}
            />
            <Label>{t('status')}</Label>
            <select
              className='select is-small'
              onChange={(e) => setAlteredCustomer({ ...alteredCustomer, status: e.target.value })}
              defaultValue={alteredCustomer.status}
            >
              <option value={'ativo'}>Ativo</option>
              <option value={'inativo'}>Inativo</option>
            </select>
          </div>
        </div>
        <div className='modal-card-foot' style={{ display: "flex", justifyContent: "space-between" }}>
          <button
            type='button'
            className="button is-success is-small"
            onClick={() => {
              updateCustomer()
              openModal()
            }}
          >
            {t('save')}
          </button>
          <button
            type="button"
            onClick={() => { openModal() }}
            className="button is-small is-warning"
          >
            {t('cancel')}
          </button>
        </div>
      </div>
    </div>
  )
}