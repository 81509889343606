import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiFillPlusCircle, AiOutlineClose, AiOutlineMinusCircle, AiOutlinePlusCircle, AiOutlineWarning } from 'react-icons/ai';
import { GoInfo } from 'react-icons/go';
import { Input } from '../../../../StyledComponents/Input/generic';
import { TagContainer, TagSpan } from '../../../../StyledComponents/Modal/generic';
import {
  BoxContainer,
  ListaTextoContainer,
  MiddleInput,
  OptionContainer,
  OptQttyContainer,
  PlusContainer,
  QuantityOptionDiv,
  SmallCheckbox,
  SmallInput
} from './FormModalStyle';
import ClearIcon from '@mui/icons-material/Clear';
import ReactInputMask from 'react-input-mask';
import ReactDatePicker from 'react-datepicker';
import { MaskHelper } from '../../../Filling/Subitens/SubitemTypes/Document';
import { ChecklistSubitem, ChecklistType, TipoSelecaoType } from '../../../../service/Checklists';
import DeleteIcon from '@mui/icons-material/Delete';
import { Tooltip } from '@mui/material';
import { DragDropContext, Draggable, DropResult, Droppable } from '@hello-pangea/dnd';
import { MdOutlineDragHandle } from 'react-icons/md';
import { toast } from 'react-toastify';

const key = 'checklists.subitemModal.';
//const questionKey = 'checklists.subitemModal.questionTypes.';

export default function SubItemTypeComponent({
  subItemType,
  avaliacao,
  setAvaliacao,
  threshold,
  setThreshold,
  naoAplica,
  padrao,
  setPadrao,
  selecao,
  setSelecao,
  padraoLista,
  setPadraoLista,
  maxNumberVal,
  setMaxNumberVal,
  minNumberVal,
  setMinNumberVal,
  setDisable,
  tipoSelecao,
  isEditing,
  form,
  subitem
}
  : {
    subItemType: string,
    avaliacao: any[] | null,
    setAvaliacao: Function,
    threshold: number | null | undefined,
    setThreshold: Function,
    naoAplica: boolean,
    padrao: any,
    setPadrao: any,
    selecao: any[] | null | undefined,
    setSelecao: Function,
    padraoLista: any[] | null | undefined,
    setPadraoLista: Function,
    maxNumberVal: number | null,
    setMaxNumberVal: Function,
    minNumberVal: number | null,
    setMinNumberVal: Function,
    disable: boolean,
    setDisable: Function,
    tipoSelecao: TipoSelecaoType | undefined,
    subitem: ChecklistSubitem,
    isEditing: boolean,
    form: ChecklistType
  }) {
  const { t } = useTranslation('translation');
  const [seeMinVal, setSeeMinVal] = useState(false);
  const [seeMaxVal, setSeeMaxVal] = useState(false);

  useEffect(() => {
    padrao && setPadrao(padrao)
    if (maxNumberVal && (typeof maxNumberVal === 'number' || maxNumberVal === 0)) setSeeMaxVal(true)
    if (minNumberVal && (typeof minNumberVal === 'number' || minNumberVal === 0)) setSeeMinVal(true)
    maxNumberVal && setSeeMaxVal(true)
    minNumberVal && setSeeMinVal(true)
    if (Number(minNumberVal) < Number(maxNumberVal)) setDisable(true)
    if (Number(maxNumberVal) > Number(minNumberVal)) setDisable(true)
  }, [
    padrao,
    selecao,
    setPadrao,
    minNumberVal,
    maxNumberVal,
    setDisable
  ]);

  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      avaliacao as any[],
      result.source.index,
      result.destination.index
    );

    setAvaliacao(items);
  }

  const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/


  const placaCarro = (value: string) => {
    return value
      .toUpperCase()
      .replace(/\W/, '')
      .replace(/(\w)(\W)/, '$1')
      .replace(/^(\d)/, '')
      .replace(/^(\w)(\d)/, '$1')
      .replace(/^(\w)(\w)(\d)/, '$1$2')
      .replace(/^(\w{3})(\D)/, '$1')
      .replace(/^(\w{5})(\D)/, '$1')
      .replace(/^(\w{6})(\D)/, '$1')
      .replace(/^(\D{3})(\w)/, '$1-$2')
      .slice(0, 8)
  };

  const CheckNumber = ({ minNumberVal, maxNumberVal, padrao }: { minNumberVal: number, maxNumberVal: number, padrao: number }) => {
    return (
      <>
        {(Number(maxNumberVal) < Number(minNumberVal)) &&
          <span style={{ fontSize: '.5em' }}>
            Valor maximo não pode ser menor que valor mínimo
          </span>
        }
        {(Number(minNumberVal) > Number(maxNumberVal)) &&
          <span style={{ fontSize: '.5em' }}>
            Valor minimo não pode ser aior que valor máximo
          </span>
        }
        {Number(padrao) > Number(maxNumberVal) &&
          <span style={{ fontSize: '.5em' }}>
            Valor padrão não pode ser maior do que o valor máximo
          </span>}
        {Number(padrao) < Number(minNumberVal) &&
          <span style={{ fontSize: '.5em' }}>
            Valor padrão não pode ser menor do que o valor minimo
          </span>}
      </>
    )

  }

  const InputValue = ({ element, index }: { element: { valor: number, nome: string }, index: number }) => {
    const [value, setValue] = useState<number | string>(element.valor);

    const thisRef = useRef<HTMLInputElement>(null)

    return (
      <SmallInput
        ref={thisRef}
        disabled={!isEditing}
        key={index}
        className='input'
        value={value}
        // style={{ width: '40px' }}
        onBlur={() => {
          if (thisRef && thisRef.current) {
            const val = Number(thisRef.current.value);
            avaliacao?.forEach((ava, i) => {
              if (i === index) {
                ava.valor = isNaN(val) ? 0 : val;
                if (form.valorMaxScoring && !isNaN(val) && val > form.valorMaxScoring) {
                  toast.warning(`Max de Scoring deste formulário: ${form.valorMaxScoring}`);
                  setValue(form.valorMaxScoring)
                  ava.valor = form.valorMaxScoring
                }
              }
            })
          }
        }}
        onChange={(eve) => {
          if (!isNaN(Number(eve.target.value)) || !eve.target.value) {
            setValue(eve.target.value ? Number(eve.target.value) : '')
          }
        }}
      />
    )
  }

  const ajustarLinearidade = () => {
    if (subItemType === 'avaliacao' && avaliacao?.length) {
      const values: { [index: number]: number } = {}
      avaliacao?.forEach((ava, i) => {
        const valorMax = form.valorMaxScoring || 100;
        const hasNA = avaliacao?.some(avaliacao => avaliacao.valor < 0);
        const eachValue = hasNA ? valorMax / (avaliacao.length - 2): valorMax / (avaliacao.length - 1);
        if ((!hasNA && i === avaliacao?.length -1) || (hasNA && i === avaliacao?.length -2)) {
          values[i] = valorMax;
        } else if (i) {
          values[i] = Math.floor(eachValue * i)
        } else {
          values[i] = 0;
        }
      })
      avaliacao?.forEach((ava, i) => {
        ava.valor = values[i];
      })
      setAvaliacao([...avaliacao || []]);
    }
  }

  switch (subItemType) {
    case 'avaliacao':
      return (
        <OptQttyContainer>
          <label>
            {t(key + 'defaultValue')}
          </label>
          <select
            disabled={!isEditing}
            className='select is-fullwidth'
            value={padrao ? padrao : ''}
            onChange={(e) => {
              setPadrao(e.target.value)
            }}>
            <option value="">
              {t('checklists.subitemModal.noDefault')}
            </option>
            {avaliacao && avaliacao.map((e, i) => (
              <option value={i} key={i}>
                {e.nome}
              </option>
            ))}
          </select>
          {avaliacao && avaliacao.length && (
            <QuantityOptionDiv>
              <span style={{ fontSize: '10px' }}>{t(key + 'optQty')}</span>
              <div style={{ display: 'flex' }}>

                <button
                  disabled={!isEditing  || avaliacao.length <= 2}
                  onClick={() => {
                    if (avaliacao && avaliacao.length > 2) {
                      avaliacao.pop()
                      setAvaliacao([...avaliacao])
                    }
                  }}
                >
                  <AiOutlineMinusCircle />
                </button>
                <input
                  style={{ width: '25px' }}
                  value={avaliacao ? avaliacao.length : 0} onChange={() => { }}
                  disabled
                />
                <button
                  disabled={!isEditing}
                  onClick={() => {
                    if (avaliacao) {
                      const obj = {
                        nome: `${avaliacao.length + 1}`,
                        valor: form.valorMaxScoring
                      }
                      setAvaliacao([...avaliacao, obj])
                    } else setAvaliacao([{ nome: '1', valor: 0 }])
                  }}
                >
                  <AiOutlinePlusCircle />
                </button>
              </div>
            </QuantityOptionDiv>
          )}
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', marginBottom: '5px', marginLeft: '21%', marginRight: '7px' }}>
            <Tooltip title={`${t(key + 'setCustomValue')}`} >
              <span className="tag is-success">
                <GoInfo />
              </span>
            </Tooltip>
            <Tooltip title={`${t(key + 'setLevel')}`}>
              <span className="tag is-warning">
                <AiOutlineWarning />
              </span>
            </Tooltip>
          </div>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable isDropDisabled={!isEditing} droppableId="avaliacao">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {avaliacao && avaliacao.map((e, i) => (
                    e.valor !== -1 &&
                    <Draggable key={i} draggableId={`${subitem.id}|${i}`} index={i} isDragDisabled={!isEditing}>
                      {(provided) => (
                        <div key={i} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                            <div style={{ width: '20%', border: '1px solid darkgrey', textAlign: 'center', marginRight: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '5px', borderRadius: '5px', cursor: isEditing ? 'grab' : 'not-allowed' }}>
                              <MdOutlineDragHandle />
                            </div>
                            <OptionContainer>
                              <InputValue
                                element={e}
                                index={i}
                              />
                              <MiddleInput
                                disabled={!isEditing}
                                type="text"
                                className='input'
                                value={e.nome}
                                onChange={(eve) => {
                                  if (avaliacao)
                                    avaliacao[i].nome = eve.target.value
                                  setAvaliacao([...avaliacao])
                                }
                                }
                              />
                              <SmallCheckbox
                                style={{ cursor: !isEditing ? 'not-allowed' : 'pointer' }}
                                onClick={() => {
                                  if (!isEditing) {
                                    if (i === threshold) { setThreshold(i - 1); }
                                    else { setThreshold(i) }
                                  }
                                }}
                              >
                                <input
                                  type='checkbox'
                                  style={{ opacity: threshold ? threshold > i ? '0.5' : '1' : threshold === 0 ? threshold >= i ? '1' : '1' : '1' }}
                                  checked={threshold ? threshold >= i : threshold === 0 ? threshold >= i : false}
                                  disabled={!isEditing}
                                  onChange={(e) => {
                                    if (i === threshold) { setThreshold(i - 1); }
                                    else { setThreshold(i) }
                                  }}
                                />
                              </SmallCheckbox>
                            </OptionContainer>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <button className='button is-info is-small' onClick={() => ajustarLinearidade()}>
            Ajustar Linearidade
          </button>
          {/* {avaliacao && avaliacao.map((e, i) => (
            e.valor !== -1 &&
            <OptionContainer key={i}>
              <InputValue
                element={e}
                index={i}
              />
              <MiddleInput
                disabled={!isEditing}
                type="text"
                className='input'
                value={e.nome}
                onChange={(eve) => {
                  if (avaliacao)
                    avaliacao[i].nome = eve.target.value
                    setAvaliacao([...avaliacao])
                }
                }
              />
              <SmallCheckbox>
                <input type='checkbox' checked={threshold ? threshold >= i : threshold === 0 ? threshold >= i : false}
                  disabled={(threshold ? threshold > i : false) || !isEditing}
                  onChange={(e) => {
                    if (e.target.checked) setThreshold(i)
                    else setThreshold(-1)
                  }}
                />
              </SmallCheckbox>
            </OptionContainer>
          ))} */}
          {naoAplica && (
            <OptionContainer key={'naoAplica'}>
              <SmallInput className='input' defaultValue={'-'} disabled />
              <MiddleInput type="text" className='input' defaultValue={`${t('checklists.printConfig.Nao_se_aplica')}`} disabled />
              <SmallCheckbox
                style={{ cursor: 'not-allowed', backgroundColor: '#F5F5F5' }}
              >
              </SmallCheckbox>
            </OptionContainer>
          )}
        </OptQttyContainer>
      )
    case 'multiple':
      return (
        <>
          {tipoSelecao === 'default' && <OptQttyContainer>
            <label>
              {t(key + 'defaultValue')}
            </label>
            <TagContainer>
              {Array.isArray(padrao) && padrao?.map((e, i) => (
                <TagSpan key={i} onClick={() => setPadrao(padrao?.filter(el => el !== e))}>
                  {selecao ? selecao[e] : 'kkk sei la'}
                  <AiOutlineClose />
                </TagSpan>
              ))}
            </TagContainer>
            <select
              disabled={!isEditing}
              className='select is-fullwidth'
              value={padrao ? padrao : ''}
              onChange={(e) => {
                if (Array.isArray(padrao)) setPadrao([...padrao, e.target.value]);
                else setPadrao([e.target.value])
              }}>
              <option value="" disabled>
                {t('checklists.subitemModal.noDefault')}
              </option>
              {selecao && selecao.map((e, i) => (
                <option value={i} key={i} disabled={Array.isArray(padrao) && padrao?.includes(String(i))}>
                  {e}
                </option>
              ))}
            </select>
            <QuantityOptionDiv style={{ marginBottom: '10px' }}>
              <span style={{ fontSize: '10px', width: '30%' }}>{t(key + 'optQty')}</span>
              <div className='wrapper-opt-input' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '5px' }}>
                <button
                  disabled={!isEditing}
                  onClick={() => {
                    if (selecao && selecao.length > 2) {
                      selecao.pop()
                      setSelecao([...selecao])
                    }
                  }}
                >
                  <AiOutlineMinusCircle />
                </button>
                <input value={selecao?.length} onChange={() => { }} disabled style={{ width: '25px' }} />
                <button
                  disabled={!isEditing}
                  onClick={() => {
                    if (selecao) {
                      setSelecao([...selecao, `${selecao.length + 1}`])
                    }
                  }}
                >
                  <AiOutlinePlusCircle />
                </button>
              </div>
              <button disabled={!isEditing} onClick={() => {
                const novoArray = selecao?.sort((a: string, b: string) => { return a?.toLowerCase() > b?.toLowerCase() ? 1 : - 1 })
                setSelecao([...novoArray as string[]])
              }}
              >
                Ordenar
              </button>
            </QuantityOptionDiv>
            {selecao && selecao.map((e, i) => (
              <OptionContainer key={i}>
                <SmallInput className='input' defaultValue={i} disabled />
                <MiddleInput disabled={!isEditing} type="text" className='input' value={e}
                  onChange={(eve) => {
                    selecao[i] = eve.target.value
                    setSelecao([...selecao])
                  }
                  }
                />
              </OptionContainer>
            ))}
          </OptQttyContainer>}
        </>
      )

    case 'unique':
      return (
        <>
          {tipoSelecao === 'default' && <OptQttyContainer>
            <label>
              {t(key + 'defaultValue')}
            </label>
            <select
              disabled={!isEditing}
              className='select is-fullwidth'
              value={padrao ? padrao : ''}
              onChange={(e) => {
                setPadrao(e.target.value);
              }}>
              <option value="">
                {t('checklists.subitemModal.noDefault')}
              </option>
              {selecao && selecao.map((e, i) => (
                <option value={i} key={i}>
                  {e}
                </option>
              ))}
            </select>
            <QuantityOptionDiv style={{ marginBottom: '10px' }}>
              <span style={{ fontSize: '10px' }}>{t(key + 'optQty')}</span>
              <div className='wrapper-opt-input'>
                <button
                  disabled={!isEditing}
                  onClick={() => {
                    if (selecao && selecao.length > 2) {
                      selecao.pop()
                      setSelecao([...selecao])
                    }
                  }}
                >
                  <AiOutlineMinusCircle />
                </button>
                <input value={selecao?.length} onChange={() => { }} disabled style={{ width: '25px' }} />
                <button
                  disabled={!isEditing}
                  onClick={() => {
                    if (selecao) {
                      setSelecao([...selecao, `${selecao.length + 1}`])
                    }
                  }}
                >
                  <AiOutlinePlusCircle />
                </button>
              </div>
              <button disabled={!isEditing} onClick={() => {
                const novoArray = selecao?.sort((a: string, b: string) => { return a?.toLowerCase() > b?.toLowerCase() ? 1 : - 1 })
                setSelecao([...novoArray as string[]])
              }}
              >
                Ordenar
              </button>
            </QuantityOptionDiv>
            {selecao && selecao?.map((e, i) => (
              <OptionContainer key={i}>
                <SmallInput className='input' defaultValue={i} disabled />
                <MiddleInput disabled={!isEditing} type="text" className='input' value={e}
                  onChange={(eve) => {
                    selecao[i] = eve.target.value
                    setSelecao([...selecao])
                  }
                  }
                />
              </OptionContainer>
            ))}
          </OptQttyContainer>}
        </>
      )
    case 'textoCurto':
      return (
        <BoxContainer>
          <label>
            {t(key + 'defaultValue')}
            <input
              disabled={!isEditing}
              className='input is-fullwidth'
              defaultValue={padrao ? padrao : ''}
              onChange={(e) => setPadrao(e.target.value)}
            />
          </label>
        </BoxContainer>
      )
    case 'textoLongo':
      return (
        <BoxContainer>
          <label>
            {t(key + 'defaultValue')}
            <textarea
              disabled={!isEditing}
              className='textarea is-medium'
              defaultValue={padrao ? padrao : ''}
              onChange={(e) => setPadrao(e.target.value)}
            />
          </label>
        </BoxContainer>
      )
    case 'list':
      return (
        <BoxContainer>
          <label onClick={() => {
            if (Array.isArray(padraoLista)) setPadraoLista([...padraoLista, ''])
            else setPadraoLista([''])
          }}>
            <PlusContainer>
              <AiFillPlusCircle />
              Adicionar um novo valor padrão
            </PlusContainer>
          </label>
          {padraoLista && padraoLista.map((e, i) => (
            <ListaTextoContainer key={i}>
              <button disabled={!isEditing} className='button is-danger' onClick={() => setPadraoLista(padraoLista.filter((_el, index) => index !== i))}>
                X
              </button>
              <input disabled={!isEditing} type="text" className='input' value={e} placeholder={'Escreva aqui'}
                onChange={(eve) => {
                  padraoLista[i] = eve.target.value
                  setPadraoLista([...padraoLista])
                }
                }
              />
            </ListaTextoContainer>
          ))}
        </BoxContainer>
      )
    case 'email':
      return (
        <BoxContainer>
          <label>
            {t(key + 'defaultValue')}
          </label>
          <Input disabled={!isEditing} type='email' value={padrao} onChange={(e) => {
            setPadrao(e.target.value)
          }} className='input' />
          {padrao?.length > 1 && !regexEmail.test(padrao) && <span style={{ fontSize: '12px', fontWeight: 600 }}>e-mail inválido</span>}
        </BoxContainer>
      )
    case 'placa':
      return (
        <BoxContainer>
          <label>
            {t(key + 'defaultValue')}
          </label>
          <Input
            disabled={!isEditing}
            maxLength={8}
            type='text'
            value={padrao}
            onChange={async (e) => setPadrao(placaCarro(e.target.value))}
            className='input'
          />
        </BoxContainer>
      )
    case 'cpfcnpj':
      return (
        <BoxContainer>

          <label>
            {t(key + 'defaultValue')}
          </label>
          <Input
            disabled={!isEditing}
            value={padrao}
            onChange={(e) => {
              const newValue = MaskHelper.cpfCnpj(e.target.value)
              setPadrao(newValue)
            }} className='input'
          />

        </BoxContainer>
      )
    case 'cep':
      return (
        <BoxContainer>
          <label>
            {t(key + 'defaultValue')}
          </label>
          <ReactInputMask
            disabled={!isEditing}
            mask='99999-999'
            maskChar={''}
            value={padrao}
            onChange={(e) => setPadrao(e.target.value)}
            className='input'
          />

        </BoxContainer>
      )
    case 'assinatura':
      return (
        <BoxContainer>
          <label>
          </label>
        </BoxContainer>
      )
    case 'rg':
      return (
        <BoxContainer>
          <label>
            {t(key + 'defaultValue')}
          </label>
          <ReactInputMask
            disabled={!isEditing}
            value={padrao}
            onChange={(e) => setPadrao(e.target.value)}
            mask='99.999.999-99'
            maskChar=''
            alwaysShowMask={false}
            className="input is-small"
            placeholder='99.999.999-99'
          />
        </BoxContainer>
      )
    case 'data':
      return (
        <>
          <BoxContainer>
            <label>
              {t(key + 'defaultValue')}
            </label>
            <ReactDatePicker
              showMonthDropdown
              showYearDropdown
              disabled={!isEditing}
              portalId="root-portal"
              locale="pt"
              dateFormat='dd/MM/yyyy'
              wrapperClassName="inputDateStyle"
              className='input'
              minDate={new Date()}
              onKeyDown={(e) => e.preventDefault()}
              selected={padrao === undefined || typeof padrao !== 'number' ? undefined  : new Date(padrao)}
              onChange={(date: Date) => setPadrao(new Date(date).getTime())}
            />
            {typeof padrao === 'number' && <button
              className='button is-small is-danger'
              onClick={() => setPadrao(undefined)}>
              Limpar valor padrão
              <DeleteIcon />
            </button>}
          </BoxContainer>
        </>
      )
    case 'hora':
      return (
        <BoxContainer>
          <label>
            {t(key + 'defaultValue')}
          </label>
          <ReactInputMask
            disabled={!isEditing}
            mask='99:99'
            maskChar=''
            alwaysShowMask={false}
            className="input is-small"
            placeholder='00:00'
            onChange={(e) => setPadrao(e.target.value)}
            value={padrao}
          />
        </BoxContainer>
      )
    case 'telefone':
      return (
        <BoxContainer>
          <label>
            {t(key + 'defaultValue')}
          </label>
          <ReactInputMask
            disabled={!isEditing}
            value={padrao ? padrao : undefined}
            onChange={(e) => setPadrao(e.target.value)}
            mask='99 9999-99999'
            maskChar=''
            alwaysShowMask={false}
            className="input is-small"
            placeholder='99 9999-99999'
          />
        </BoxContainer>
      )
    case 'numero':
      return (
        <BoxContainer>
          <label>
            {t(key + 'defaultValue')}
            <input
              disabled={!isEditing}
              value={padrao}
              type='text'
              onChange={(e) => {
                setPadrao(e.target.value as unknown as number)
              }}
              className='input is-fullwidth'
            />
          </label>
          <label className='checkbox'>
            <input
              disabled={!isEditing}
              className='checkbox'
              type='checkbox'
              checked={seeMinVal}
              onChange={(e) => {
                setSeeMinVal(e.target.checked)
                if (!e.target.checked) setMinNumberVal(undefined);
              }} />
            Especificar valor mínimo
          </label>
          <>
          </>
          {seeMinVal &&
            <div style={{ width: '100%', display: 'flex' }}>
              {/* <button
                className='button is-small'
                onClick={() => setMinNumberVal((prev: number) => prev < (maxNumberVal as number) || !maxNumberVal ? (prev === undefined ? 0 : prev ) + 1 : prev)}>
                <ControlPointIcon />
              </button> */}
              <input
                disabled={!isEditing}
                type='number'
                value={Number(minNumberVal)}
                onChange={(e) => {
                  setMinNumberVal(Number(e.target.value))
                }}
                className='input is-small'
                style={{
                  textAlign: 'center',
                  maxWidth: '100px',
                  border: '1px solid gray'
                }} />
              {/* <button
                className='button is-small'
                onClick={() => setMinNumberVal((prev: number) => prev < (maxNumberVal as number) || !maxNumberVal ? (prev === undefined ? 0 : prev ) - 1 : prev)}>
                <RemoveCircleOutlineIcon />
              </button> */}
              <button disabled={!isEditing} style={{ marginLeft: '.5em' }} className='button is-small' onClick={() => {
                setMinNumberVal(undefined)
                minNumberVal = null
              }}><ClearIcon /></button>
            </div>
          }
          <label className='checkbox'>
            <input
              disabled={!isEditing}
              className='checkbox'
              type='checkbox'
              checked={seeMaxVal}
              onChange={(e) => {
                setSeeMaxVal(!seeMaxVal)
                if (!e.target.checked) setMaxNumberVal(undefined);
              }} />
            Especificar valor máximo
          </label>
          {seeMaxVal &&
            <div style={{ width: '100%', display: 'flex' }}>
              {/* <button
                className='button is-small'
                onClick={() => setMaxNumberVal((prev: number) => prev > (minNumberVal as number) || !minNumberVal ? (prev === undefined ? 0 : prev ) + 1 : prev)}>
                <ControlPointIcon />
              </button> */}
              <input
                disabled={!isEditing}
                type='number'
                value={Number(maxNumberVal)}
                onChange={(e) => setMaxNumberVal(Number(e.target.value))}
                className='input is-small'
                style={{
                  textAlign: 'center',
                  maxWidth: '100px',
                  border: '1px solid gray'
                }} />
              {/* <button
                className='button is-small'
                onClick={() => setMaxNumberVal((prev: number) => prev > (minNumberVal as number) || !minNumberVal ? (prev === undefined ? 0 : prev ) - 1 : prev)}>
                <RemoveCircleOutlineIcon />
              </button> */}
              <button disabled={!isEditing} style={{ marginLeft: '.5em' }} className='button is-small' onClick={() => {
                setMaxNumberVal(undefined)
                maxNumberVal = null
              }}><ClearIcon /></button>
            </div>
          }
          <CheckNumber minNumberVal={Number(minNumberVal)} maxNumberVal={Number(maxNumberVal)} padrao={Number(padrao)} />
          {/* {Number(maxNumberVal) < Number(minNumberVal) && <span style={{ fontSize: '.5em' }}>Valor maximo não pode ser menor que valor mínimo</span>}
          {Number(minNumberVal) > Number(maxNumberVal) && <span style={{ fontSize: '.5em' }}>Valor minimo não pode ser aior que valor máximo</span>} */}
        </BoxContainer>
      )

    default:
      return (<div>AAAAAAA</div>)
  }
}