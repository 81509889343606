import { useContext } from "react";
import Dashboard from "./Dashboard";
import { useQuery } from "@tanstack/react-query";
import AttToken from "../../helpers/attToken";
import { UserContext } from "../../context/UserContext";
import register from '../../service/PowerBi';

export default function DashboardTabs() {
  const { openModal } = useContext(UserContext);
  const servicePowerBi = new register();

  const { data, isLoading } = useQuery({
    queryKey: ['GET_USER_REPORTS'],
    queryFn: async () => {
      const token = await AttToken()
      if (token) {
        const args = {
          empresa: token.userEmpresa, //'-Lmyih0t3MjeBjSGrNxE',
          userAdm: token.username,
        }
        const response = await servicePowerBi.getUserReports(token.userToken, args)
        if (!response?.length || !response) openModal()
        return response
      }
    },
    refetchOnMount: 'always',
    keepPreviousData: true,
    retry: 5,
    refetchOnWindowFocus: false,
  })

  return (
      <Dashboard userReportsData={data} isLoadingUserReports={isLoading} />
  )
}