import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import Clients, { ClientType } from '../../service/Clients';
import Places, { PlaceType } from '../../service/Places';
import { Label } from '../../StyledComponents/Input/generic';
import PlacesAcordeon from './PlacesAcordeon';
import { MainWrrapper } from './style';
import { UserContext } from '../../context/UserContext';
import NewPlaceModal from './Modals/NewPlaceModal';
import AttToken from '../../helpers/attToken';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Autocomplete, Pagination, Skeleton } from '@mui/material';
import { TextField, Typography } from '@material-ui/core';

const clientsService = new Clients();
const placesService = new Places();

export type reorderType = {
  [key: string]: string
}

export default function PlacesComponent() {
  const { customerId, setCustomerId, setPlacesList } = useContext(UserContext);
  const [selected, setSelected] = useState(['initial']);
  const { popUp, openModal, hierarchyLabel } = useContext(UserContext);
  const [search, setSearch] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [paginated, setPaginated] = useState<PlaceType[]>([]);
  const [expanded, setExpanded] = useState<string | false>(false);
  const [reorderBy, setReorderBy] = useState<string>('createdBy');
  const [local, setLocal] = useState<reorderType>({});

  const handleChange = (isExpanded: boolean, panel: string) => {
    setExpanded(isExpanded ? panel : false);
  };

  const { t } = useTranslation('translation');

  const { data, isLoading: loadingClientes } = useQuery({
    queryKey: ['GET_CLIENTES'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const response = await clientsService.getAllClients(token, false);
        return response.clientList;
      }
    },
    retry: 5,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  })

  const { data: placesData, isLoading: isLoadingPlaces, isSuccess, mutate } = useMutation({
    mutationKey: ['GET_PLACES'],
    mutationFn: async (value: string) => {
      const token = await AttToken();
      if (token) {
        const response = await placesService.getPlacesWithChildren(token, value)
        return response
      }
    },
  })

  const lastPageIndex = currentPage * itemsPerPage;
  const firstPageIndex = lastPageIndex - itemsPerPage;

  const switchSort = (value: string, a: PlaceType, b: PlaceType) => {
    switch (value) {
      case 'createBy':
        return (a.dataCadastro < b.dataCadastro)
      case 'crescent':
        return (a.nome.toLowerCase() > b.nome.toLowerCase())
      case 'decrescent':
        return (a.nome.toLowerCase() < b.nome.toLowerCase())
      default: return (a.dataCadastro < b.dataCadastro)
    }
  }

  useEffect(() => {
    if (placesData && isSuccess) {
      const currentPageData = placesData.locais?.sort((a: PlaceType, b: PlaceType) =>
        switchSort(reorderBy, a, b) ? 1 : -1
      )
        ?.filter((e: PlaceType) => e.nome.toLowerCase().includes(search.toLowerCase()))
      search ? setPaginated(currentPageData?.slice(0, 5)) : setPaginated(currentPageData?.slice(firstPageIndex, lastPageIndex))
    }
  }, [firstPageIndex, lastPageIndex, search, reorderBy, isSuccess])

  let pages: any = [];

  for (let i = 1; i <= Math.ceil(placesData?.locais?.length / itemsPerPage); i++) {
    pages.push(i)
  }

  useEffect(() => {
    if (placesData) setPlacesList(placesData.locais)
  }, [isLoadingPlaces, isSuccess])

  return (
    <MainWrrapper>
      {popUp && <NewPlaceModal getPlaces={mutate} />}
      {loadingClientes ? <Skeleton variant='rectangular' height={30} /> :
          <Autocomplete
            isOptionEqualToValue={(option, value) => option.value === value.value}
            filterSelectedOptions
            options={
              data
                ?.sort((a: ClientType, b: ClientType) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                ?.filter((e: ClientType) => e.status === 'ativo')
                ?.map((ele: ClientType) => ({
                  label: ele.nome,
                  value: ele.id,
                }))}
            onChange={(event: any, newValue: { label: string, value: string } | null) => {
              setCustomerId(newValue?.value)
              newValue && mutate(newValue?.value as string);
              const stringLocal = localStorage.getItem('localOrder')
              if (stringLocal) {
                const storageLocal = JSON.parse(stringLocal)
                if (storageLocal) {
                  if (storageLocal[newValue?.value as string]) {
                    setReorderBy(storageLocal[newValue?.value as string])
                  } else setReorderBy('createdBy')
                  setLocal(storageLocal);
                }
              }
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props} key={option.value}>
                <span>{option.label}</span>
              </li>)}
            renderInput={(params) => <TextField {...params} label={`${t('Services.selectCustomer')} ${hierarchyLabel.cliente}:`} />}
          />
        
      }
      {customerId?.length > 0 &&
        <>
          <button
            style={{ marginTop: '10px', alignSelf: 'flex-start' }}
            className='button is-success'
            onClick={() => openModal()}
            disabled={customerId === ''}
          >
            {`${t('add')} ${hierarchyLabel.local} +`}
          </button>
          {isLoadingPlaces ? (
            <>
            </>
          ) :
            <>
              {paginated?.length > 0 &&
                <Label style={{ display: 'flex', flexDirection: 'column' }}>
                  {`${t('search')} ${hierarchyLabel.local}`}
                  <input value={search} onChange={(even) => setSearch(even.target.value.toLowerCase())} className='input is-small' />
                </Label>}
            </>}
          {isLoadingPlaces ? (
            <>
              <Skeleton width={80} height={15} />
              <Skeleton height={50} style={{ marginTop: '-.5em', marginBottom: '-.5em' }} />
              <Skeleton width={200} height={50} style={{ marginBottom: '-2.5em', marginTop: '-.2em' }} />
            </>
          ) :
            paginated?.length > 0 && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: '.5em', marginTop: '.5em' }}>
              <div style={{ marginBottom: '5px' }}>
                {pages.length > 1 &&
                  <Pagination
                    style={{ display: `${!pages?.length ? 'none' : ''}` }}
                    showFirstButton
                    showLastButton
                    shape="rounded"
                    variant="outlined"
                    count={pages?.length}
                    defaultPage={currentPage}
                    onChange={(eve, pageNumber) => setCurrentPage(pageNumber)}
                  />}
              </div>
            </div>}
          {isLoadingPlaces ? (
            <>
              <div style={{ display: 'flex', width: '100%', marginTop: 40 }}>
                <Skeleton variant='text' width={60} height={18} />
              </div>
              <Typography style={{ marginBottom: '.2em' }} variant="h2">{<Skeleton variant='rounded' />}</Typography>
            </>
          ) : (
            <div>
              {paginated.length > 0 &&
                <Label style={{ fontSize: '15px' }}>{`${hierarchyLabel.local}:`}
                  <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '15px' }}>
                    <Label>Ordenar por:</Label>
                    <select
                      defaultValue={reorderBy}
                      onChange={(event) => {
                        setReorderBy(event.target.value)
                        local[customerId] = event.target.value
                        setLocal(local)
                        localStorage.setItem('localOrder', JSON.stringify(local))
                      }}
                      style={{ margin: '0px 5px 0px 5px' }}
                      className='select is-small'>
                      <option value={'createBy'}>Por data de criação</option>
                      <option value={'crescent'}>Alfabética crescente (A-Z)</option>
                      <option value={'decrescent'}>Alfabética decrescente (Z-A)</option>
                    </select>
                  </div>
                </Label>
              }
              {paginated?.map((e: PlaceType) => (
                <PlacesAcordeon
                  pages={pages}
                  setCurrentPage={setCurrentPage}
                  local={local}
                  setLocal={setLocal}
                  expanded={expanded}
                  handleChange={handleChange}
                  place={e}
                  key={e.id}
                  getPlaces={mutate}
                  selected={selected}
                  setSelected={setSelected}
                />
              ))}
            </div>
          )}
        </>
      }
    </MainWrrapper >
  );
};
