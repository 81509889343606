import { useEffect, useMemo, useState } from 'react'
import { NotificaSobre, Team } from '../../service/Teams'
import { useQuery } from '@tanstack/react-query';
import AttToken from '../../helpers/attToken';
import Clients, { ClientsWithChildren } from '../../service/Clients';
import Loading from '../Loading/Loading';
import { WarningSpan } from '../Checklists/FormStyle';
import { Accordion, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Label } from '../Notifications/style/SingleNotificationStyle';
import { Pagination, Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

type props = {
  tipoServico: string;
  notificaSobre: NotificaSobre[] | null;
  setShowingAssociation: Function;
  nomeServico: string;
  equipe: Team
}

type AccordeonPlacesParams = {
  client: ClientsWithChildren;
  notificaSobre: NotificaSobre | null;
  equipe: Team,
  tipoServico: string,
  expanded: string | boolean,
  handleChange: Function,
  index: number
}

export default function TypePlaceNotification({ tipoServico, notificaSobre, setShowingAssociation, nomeServico, equipe }: props) {
  const thisTipoLocal = notificaSobre ? notificaSobre.find(e => e.tipo === tipoServico) || null : null;

  const [expanded, setExpanded] = useState<string | boolean>(false);
  const [searchCliente, setSearchCliente] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [paginated, setPaginated] = useState<ClientsWithChildren[]>([]);

  const handleChange = (isExpanded: boolean, panel: string) => {
    setExpanded(isExpanded ? panel : false);
  };

  const CustomerService = useMemo(() => new Clients(), []);
  const { t } = useTranslation('translation');

  const { data: ClientesChildren, isLoading: isLoadingClientesChildren, isFetching } = useQuery({
    queryKey: ['ClientesChildren'],
    queryFn: async () => {
      const data = await AttToken();
      if (data) {
        const teams = await CustomerService.getAllWithSets(data, tipoServico);
        return teams
      } else throw new Error('aaaa')
    },
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  });

  const lastPageIndex = currentPage * itemsPerPage;
  const firstPageIndex = lastPageIndex - itemsPerPage;

  useEffect(() => {
    if (ClientesChildren) {
      const currentPageData = ClientesChildren?.sort((a: ClientsWithChildren, b: ClientsWithChildren) => { return a.nome < b.nome ? 1 : -1 })
        ?.filter((e: ClientsWithChildren) => e.nome?.toLowerCase()?.includes(searchCliente?.toLowerCase()))
      searchCliente ? setPaginated(currentPageData?.slice(0, 5)) : setPaginated(currentPageData?.slice(firstPageIndex, lastPageIndex))
    }
  }, [firstPageIndex, lastPageIndex, ClientesChildren, searchCliente])

  let pages = [];

  for (let i = 1; i <= Math.ceil(ClientesChildren?.length / itemsPerPage); i++) {
    pages.push(i)
  }

  if (isFetching || isLoadingClientesChildren) return (
    <>
      <Skeleton variant='rounded' sx={{ width: '50%', marginBottom: '5px' }} height={25} />
      <Skeleton variant='rounded' sx={{ width: '30%', marginBottom: '5px' }} height={30} />
      <Skeleton variant='rounded' sx={{ width: '45%', marginBottom: '5px' }} height={25} />
      <Skeleton variant='rounded' sx={{ width: '20%', marginBottom: '5px' }} height={25} />
      <Skeleton variant='rounded' sx={{ width: '100%', marginBottom: '5px' }} height={30} />
      <Skeleton variant='rounded' sx={{ width: '45%', marginBottom: '5px' }} height={30} />
      <Skeleton variant='rounded' sx={{ width: '100%', marginBottom: '5px' }} height={50} />
    </>
  )

  return (
    <div>
      <h1>{`${nomeServico}`}</h1>
      <button
        className="button is-success is-small"
        onClick={() => {
          setShowingAssociation(false);
        }}
      >
        <ArrowBackIcon />&nbsp;{t('teamManage.registered.voltar')}
      </button>
      <h1>{t('teamManage.registered.AssociacaoConjuntos')}</h1>
      <h2>{t('teamManage.registered.Clientes')}</h2>
      {ClientesChildren.length > 5 &&
        <>
          <div style={{ marginBottom: '5px' }}>
            <input
              value={searchCliente}
              onChange={(eve) => setSearchCliente(eve.target.value)}
              style={{ marginBottom: '10px' }}
              className='input is-fullwidth'
              placeholder='Pesquisar cliente'
            />
          </div>
          <Pagination
            key={Math.random()}
            style={{ display: `${!pages?.length ? 'none' : ''}` }}
            showFirstButton
            showLastButton
            shape="rounded"
            variant="outlined"
            count={pages?.length}
            defaultPage={currentPage}
            onChange={(eve, pageNumber) => {
              setCurrentPage(pageNumber);

            }}
          />
        </>
      }
      {isLoadingClientesChildren || isFetching || !ClientesChildren ? <Loading /> : (
        paginated.map((e: ClientsWithChildren, i: number) => (
          e &&
          <AccordeonPlaces
            expanded={expanded}
            handleChange={handleChange}
            index={i}
            client={e}
            key={e.id}
            notificaSobre={thisTipoLocal}
            equipe={equipe}
            tipoServico={tipoServico}
          />
        ))
      )}
      {(!isLoadingClientesChildren && !isFetching) && ClientesChildren.length === 0 &&
        <WarningSpan>
          * Você não possui nenhuma estrutura geográfica associada a
          esse tipo, por favor associe em "Gestão de Serviços" na aba de "Gestão de Local"
          ou em "Gestão de Cliente" na aba "Meus Clientes".
        </WarningSpan>
      }
    </div>
  )
}

const AccordeonPlaces = ({ client, notificaSobre, equipe, tipoServico, expanded, handleChange, index }: AccordeonPlacesParams) => {
  const [subExpanded, setSubExpanded] = useState(-1);

  const [cnjsArr, setCnjsArr] = useState(notificaSobre ? notificaSobre.cnjs || [] : []);

  const allSetsFromClient: string[] = [];
  client.locaisList.forEach((local) => {
    local.conjuntosList.forEach((cnj) => allSetsFromClient.push(cnj.id))
  })
  const [searchLocais, setSearchLocais] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [paginated, setPaginated] = useState<any[]>([]);
  const [searchConjuntos, setSearchConjuntos] = useState<string>('');

  const lastPageIndex = currentPage * itemsPerPage;
  const firstPageIndex = lastPageIndex - itemsPerPage;

  useEffect(() => {
    if (client.locaisList) {
      const currentPageData = client.locaisList?.sort((a: any, b: any) => { return a.nome < b.nome ? 1 : -1 })
        ?.filter((e: any) => e.nome?.toLowerCase()?.includes(searchLocais?.toLowerCase()))
      searchLocais ? setPaginated(client?.locaisList?.slice(0, 5)) : setPaginated(currentPageData?.slice(firstPageIndex, lastPageIndex))
    }
  }, [firstPageIndex, lastPageIndex, client, searchLocais])

  let pages = [];

  for (let i = 1; i <= Math.ceil(client?.locaisList?.length / itemsPerPage); i++) {
    pages.push(i)
  }

  return (
    <div key={client.id}>
      <Accordion style={{ marginBottom: '10px' }} expanded={expanded === client.id}  >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon
            onClick={(isExpanded) => {
              if (expanded === client.id) {
                handleChange(!isExpanded, '')
              } else
                handleChange(isExpanded, client.id)
            }}
          />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Label className='checkbox'>
            <input
              type='checkbox'
              style={{ marginLeft: '10px', marginRight: '10px' }}
              checked={allSetsFromClient.every(set => cnjsArr.includes(set))}
              onChange={(event) => {
                const cnjs: string[] = [];
                if (event.target.checked) {
                  client.locaisList.forEach((local) => {
                    local.conjuntosList.forEach((cnj) => {
                      setCnjsArr((prev) => prev ? [...prev, cnj.id] : [cnj.id])
                      cnjs.push(cnj.id)
                    })
                  })
                  if (equipe.notificaSobre) {
                    equipe.notificaSobre.forEach(el => {
                      if (el.tipo === tipoServico) {
                        if (el.cnjs) el.cnjs = [...el.cnjs, ...cnjs];
                        else el.cnjs = cnjs;
                      }
                    })
                  }
                } else {
                  client.locaisList.forEach((local) => {
                    local.conjuntosList.forEach((cnj) => {
                      setCnjsArr((prev) => prev.filter(el => el !== cnj.id))
                      cnjs.push(cnj.id)
                    })
                  })
                  if (equipe.notificaSobre) {
                    equipe.notificaSobre.forEach(el => {
                      if (el.tipo === tipoServico) {
                        if (el.cnjs) el.cnjs = el.cnjs.filter(set => !cnjs.includes(set));
                        else el.cnjs = [];
                      }
                    })
                  }
                }
              }}
            />
            <span style={{ fontWeight: 'bold', color: 'black' }}>{client.nome}</span>
          </Label>
        </AccordionSummary>
        <h2 style={{ marginLeft: '10px', fontSize: '0.8xem' }}>Locais:</h2>
        {client.locaisList.length > 5 &&
          <>
            <div style={{ padding: '0px 5px 0px 5px' }}>
              <input
                className='input is-fullwidth'
                value={searchLocais.toLowerCase()}
                onChange={(eve) => setSearchLocais(eve.target.value.toLowerCase())}
                placeholder='Pesquisar locais' />
            </div>
            <Pagination
              key={Math.random()}
              style={{ display: `${!pages?.length ? 'none' : ''}` }}
              showFirstButton
              showLastButton
              shape="rounded"
              variant="outlined"
              count={pages?.length}
              defaultPage={currentPage}
              onChange={(eve, pageNumber) => {
                setCurrentPage(pageNumber);

              }}
            />
          </>
        }
        {client.locaisList && paginated.map((e, i) => {
          const allSetsFromPlace: string[] = e.conjuntosList.map((cnj: any) => cnj.id)
          return (
            <div key={i}>
              <Accordion style={{ backgroundColor: '#e5e5e5', borderRadius: '0', margin: '10px' }} expanded={subExpanded === i} >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  key={i}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  IconButtonProps={{
                    onClick: () => subExpanded === i ? setSubExpanded(-1) : setSubExpanded(i)
                  }}
                >
                  <Label className='checkbox'>
                    <input
                      type='checkbox'
                      style={{ marginLeft: '10px', marginRight: '10px' }}
                      checked={allSetsFromPlace.every(set => cnjsArr.includes(set))}
                      onChange={(event) => {
                        const cnjs: string[] = [];
                        if (event.target.checked) {
                          e.conjuntosList.forEach((cnj: any) => {
                            setCnjsArr((prev) => prev ? [...prev, cnj.id] : [cnj.id])
                            cnjs.push(cnj.id)
                          })
                          if (equipe.notificaSobre) {
                            equipe.notificaSobre.forEach(el => {
                              if (el.tipo === tipoServico) {
                                if (el.cnjs) el.cnjs = [...el.cnjs, ...cnjs];
                                else el.cnjs = cnjs;
                              }
                            })
                          }
                        } else {
                          e.conjuntosList.forEach((cnj: any) => {
                            setCnjsArr((prev) => prev.filter(el => el !== cnj.id))
                            cnjs.push(cnj.id)
                          })
                          if (equipe.notificaSobre) {
                            equipe.notificaSobre.forEach(el => {
                              if (el.tipo === tipoServico) {
                                if (el.cnjs) el.cnjs = el.cnjs.filter(set => !cnjs.includes(set));
                                else el.cnjs = [];
                              }
                            })
                          }
                        }
                      }}
                    />
                    <span style={{ fontWeight: 'bold', color: 'black' }}>{e.nome}</span>
                  </Label>
                </AccordionSummary>
                <h2 style={{ marginLeft: '10px', fontSize: '0.6em' }}>Conjuntos:</h2>
                {e.conjuntosList.length > 5 && <div style={{ padding: '0px 5px 0px 5px' }}>
                  <input
                    className='input is-fullwidth'
                    value={searchConjuntos.toLowerCase()}
                    onChange={(eve) => setSearchConjuntos(eve.target.value.toLowerCase())}
                    placeholder='Pesquisar conjuntos' />
                </div>}
                {e.conjuntosList && e.conjuntosList.map((el: any, ind: number) => {
                  return (
                    <Label className='checkbox' key={ind}>
                      <input
                        type='checkbox'
                        style={{ marginLeft: '25px', marginRight: '10px' }}
                        checked={cnjsArr.includes(el.id)}
                        onChange={(event) => {
                          const cnjs: string[] = [];
                          if (event.target.checked) {
                            setCnjsArr((prev) => prev ? [...prev, el.id] : [el.id])
                            cnjs.push(el.id)
                            if (equipe.notificaSobre) {
                              equipe.notificaSobre.forEach(el => {
                                if (el.tipo === tipoServico) {
                                  if (el.cnjs) el.cnjs = [...el.cnjs, ...cnjs];
                                  else el.cnjs = cnjs;
                                }
                              })
                            }
                          } else {
                            setCnjsArr((prev) => prev.filter(ele => ele !== el.id))
                            cnjs.push(el.id)
                            if (equipe.notificaSobre) {
                              equipe.notificaSobre.forEach(el => {
                                if (el.tipo === tipoServico) {
                                  if (el.cnjs) el.cnjs = el.cnjs.filter(set => !cnjs.includes(set));
                                  else el.cnjs = [];
                                }
                              })
                            }
                          }
                        }}
                      />
                      {el.nome}
                    </Label>
                  )
                })}
              </Accordion>
            </div>
          )
        })}
      </Accordion>
    </div>
  )
}
