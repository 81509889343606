import { Card } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify';
import { UserContext } from '../../../context/UserContext';
import Places, { PlaceToSend } from '../../../service/Places';
import { Label } from '../../../StyledComponents/Input/generic';
import { GenericHeader } from '../../../StyledComponents/Modal/generic';
import { TitleText } from '../../Teams/style/RegisteredTeamsStyle';
import PlacesAutocomplete from '../PlacesAutoConplete';
import { NewPlaceWrapper } from './ModalStyle';
import AttToken from '../../../helpers/attToken';
import queryClient from '../../../service/query';

const PlaceService = new Places();

export type selectedPlaceType = {
  lat: number, lng: number, address: string
}

export default function NewPlaceModal({ getPlaces } : { getPlaces: Function }) {
  const { customerId, escape, placesList, setPlacesList, editPlace, isEditing, setIsEditing } = useContext(UserContext);
  const { t } = useTranslation('translation');
  const { popUp, openModal, hierarchyLabel } = useContext(UserContext);
  const [selected, setSelected] = useState<selectedPlaceType>({ lat: 0, lng: 0, address: '' });
  const [name, setName] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (isEditing && editPlace) {
      setName(editPlace.nome)
      setSelected({
        lat: editPlace.lat,
        lng: editPlace.lng,
        address: editPlace.localizacao,
      })
    }
  }, [editPlace, isEditing])

  const create = async () => {
    setIsDisabled(true)
    try {
      const token = await AttToken();
      const PlaceObj: PlaceToSend = {
        userOwner: 'Administrador Axyma',
        cliente: customerId,
        conjuntos: [],
        dataAlteracao: Date.now(),
        lat: selected.lat,
        lng: selected.lng,
        localizacao: selected.address,
        nome: name,
      }
      if (token) {
        const newPlace = await PlaceService.newPlace(token, PlaceObj);
        openModal();
        placesList && setPlacesList([...placesList, newPlace.local])
        getPlaces(customerId);
        toast.success(`${hierarchyLabel.local} ${t('created')}!`)
        queryClient.resetQueries(['GET_LOCAIS'])
      }
    } catch (err) {
      toast.error('Erro ao criar novo local')
      return console.log(err);
    }
  }

  const edit = async () => {
    setIsDisabled(true)
    try {
      const token = await AttToken();
      if (token && editPlace) {
        const response = await PlaceService.editPlace(token, {
          lat: selected.lat,
          lng: selected.lng,
          localizacao: selected.address,
          nome: name,
          id: editPlace.id
        });
        placesList && setPlacesList(placesList.map((e) => e.id === response.id ? response : e))
        openModal();
        getPlaces(customerId);
        setIsEditing(false);
        toast.success(`${hierarchyLabel.local} ${t('edited')}!`)
        queryClient.resetQueries(['GET_LOCAIS'])
      }
    } catch (err) {
      return console.log(err);
    }
  }

  return (
    <Card>
      <TitleText>
        <div className={`modal ${popUp ? "modal is-active" : "modal"}`}>
          <div className="modal-background" onKeyDown={() => escape()} onClick={() => openModal()} />
          <div className="modal-card" onSubmit={(e: React.FormEvent) => e.preventDefault()}>
            <header className="modal-card-head" style={{ display: "flex", flexDirection: "column" }}>
              <GenericHeader>
                {isEditing ? 'Editar local' : 'Adicionar local'}
              </GenericHeader>
            </header>
            <div className="modal-card-body">
              <NewPlaceWrapper>
                <Label>
                  {t('name') + ':'}
                </Label>
                <input value={name} onChange={(e) => setName(e.target.value)} className="input is-fullwidth" />
                <Label>
                  {t('address') + ':'}
                </Label>
                <p style={{ color: 'light-gray', fontSize: '.6em', fontWeight: 700 }}>
                </p>
                <PlacesAutocomplete editPlace={editPlace?.localizacao} selected={selected} setSelected={setSelected} />
                {(!selected.lng || !selected.lng) && (
                  <span className='tag is-warning'>Por favor selecione uma localização nas opções listadas</span>
                )}
              </NewPlaceWrapper>
            </div>
            <div className='modal-card-foot' style={{ display: "flex", justifyContent: "space-between" }}>
              <button disabled={name === '' || selected.address === '' || !selected.lat || !selected.lng || isDisabled} className='button is-success is-small' onClick={isEditing ? () => edit() : () => create()}>{t('save')}</button>
              <button className='button is-warning is-small' onClick={() => {
                openModal()
                setIsEditing(false)
              }}>{t('cancel')}</button>
            </div>
          </div>
        </div>
      </TitleText>
    </Card>
  )
}
