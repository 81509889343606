import { useContext, useEffect, useMemo, useState } from "react";
import { Label } from "../Notifications/style/SingleNotificationStyle";
import RefreshIcon from '@mui/icons-material/Refresh';
import ReactDatePicker from "react-datepicker";
import { useMutation } from "@tanstack/react-query";
import AttToken from "../../helpers/attToken";
import Aprovations, { FilterAprovationsType } from "../../service/Aprovations";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { EditButton, StyledDiv } from "../TableHelpers/TableStyle";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import { tableOptions, getMuiTheme } from "../TableHelpers/options";
import { ThemeProvider } from '@mui/material/styles';
import ModalDetailPrint from "../Reports/ModalDetailPrint";
import { ChecklistReportType } from "../../service/Reports";
import Table from "../Skeletons/Table";
import { UserContext } from "../../context/UserContext";
import { toast } from "react-toastify";
import { display } from "html2canvas/dist/types/css/property-descriptors/display";
import FiltersAprovationComponent from "./FiltersAprovationComponent";

export default function History() {
  const { hierarchyLabel } = useContext(UserContext);
  const aprovationService = useMemo(() => new Aprovations(), []);
  const { t } = useTranslation('translation');

  const [dates, setDates] = useState({
    dataInicio: Date.now() - 2592000000,
    dataFim: Date.now(),
  })
  const [columnsViews, setColmnsViews] = useState<{ [k: string]: boolean }>();
  const [openModal, setOpenModal] = useState(false);
  const [selected, setSelected] = useState<ChecklistReportType>();
  const [pageNumber, setPageNumber] = useState(0);
  const [itensPerPage, setItensPerPage] = useState(10);
  const [totalItens, setTotalItens] = useState(10);
  const [filtersAprov, setFiltersAprov] = useState<FilterAprovationsType>({})

  useEffect(() => {
    const localColumnsViews = localStorage.getItem('columnsNotif');
    if (localColumnsViews) {
      setColmnsViews(JSON.parse(localColumnsViews))
    }
  }, []);


  const {
    mutate,
    data,
    isLoading
  } = useMutation({
    mutationKey: ['GET_APROVATED'],
    mutationFn: async () => {
      const response = await toast.promise(async () => {
        const token = await AttToken();
        if (token) {
          const mocked = [];
          const response = await aprovationService.getAll(
            token,
            false,
            pageNumber + 1,
            itensPerPage,
            Number(dates.dataInicio),
            Number(dates.dataFim),
            filtersAprov
          )
          if (pageNumber) {
            const mockedObj = {
              scorePorcentagem: 'Mocked',
              score: 0,
              range: 'Mocked',
              user: 'Mocked',
              username: 'Mocked',
              inicio: 1704078000000,
              fim: 1716301116388,
              formulario: 'Mocked',
              cliente: 'Mocked',
              local: 'Mocked',
              conjunto: 'Mocked',
              unidade: 'Mocked',
              identificador: 'Mocked',
              subItemIdentificador: 'Mocked',
              status: 'Mocked',
              foiDelegado: 'Mocked',
              nomeAprovador: 'Mocked',
              naoConforme: 'Mocked',
              conforme: 'Mocked',
              distancia: 'Mocked',
              latitude: 'Mocked',
              longitude: 'Mocked',
              duracao: 'Mocked',
              gerencia: 'Mocked',
              nomeEmpresa: 'Mocked',
              idCliente: 'Mocked',
              equipes: 'Mocked',
              agendamento: 'Mocked',
              aprovacao: 'Mocked',
              tempoPausa: 'Mocked',
              versaoForm: 'Mocked',
              idForm: 'Mocked',
              itens: 'Mocked'
            }
            for (let i = 0; i < pageNumber * itensPerPage; i += 1) {
              mocked.push(mockedObj)
            }
          }
          if (mocked.length) {
            const newArray = [...mocked, ...response.execucoes]
            setTotalItens(response.total)
            return newArray;
          }
          setTotalItens(response.total)
          return response.execucoes
        }
      }, {success: 'Pronto!', error: 'Erro ao buscar', pending: 'Buscando...'})
      return response
    },
    onError: () => {
       console.log(AxiosError)
    },
  });

  useEffect(() => {
    mutate()
  }, [pageNumber, itensPerPage, mutate])

  const columns = [
    {
      name: "inicio",
      label: `${t('checklists.printConfig.Inicio')}`,
      options: {
      display:false,
        filter: false,
        sort: true,
        sortThirdClickReset: true,
      }
    },
    {
      name: "inicio",
      label: `${t('checklists.printConfig.Inicio')}`,
      options: {
        filter: false,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value: number) => {
          const date = new Date(value).toLocaleString("pt-br");
          return date;
        },
      }
    },
    {
      name: 'formulario',
      label: `${t('name')}`,
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: `${'nomeEmpresa'}`,
      label: `${t('manage')}`,
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: 'cliente',
      label: hierarchyLabel.cliente,
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: 'local',
      label: hierarchyLabel.local,
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "conjunto",
      label: hierarchyLabel.conjunto,
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "unidade",
      label: hierarchyLabel.unidade,
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "user",
      label: `${t('user')}`,
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "status",
      label: 'Status',
      options: {
      }
    },
    {
      name: "comentario",
      label: `${t('notifications.ticket.Comentario')}`,
      options: {
      }
    },
  ];

  const translatedTextLabels: MUIDataTableOptions = {
    onRowClick: (rowData) => {
      const value = data.find((e: ChecklistReportType) => e.inicio === Number(rowData[0]))
      if(value) 
      setSelected(value)
      setOpenModal(true)
    },
    download: true,
    viewColumns: true,
    print: true,
    onViewColumnsChange: (changedColumn: string, action: string) => {
      if (action === 'remove') {
        setColmnsViews({
          ...columnsViews,
          [changedColumn]: false
        })
        localStorage.setItem('columnsNotif', JSON.stringify({
          ...columnsViews,
          [changedColumn]: false
        }))
      } else {
        setColmnsViews({
          ...columnsViews,
          [changedColumn]: true
        })
        localStorage.setItem('columnsNotif', JSON.stringify({
          ...columnsViews,
          [changedColumn]: false
        }))
      }
    },
    textLabels: {
      body: {
        noMatch: `${t('table.noMatch')}`,
        toolTip: `${t('table.toolTip')}`,
        columnHeaderTooltip: column =>
          `${t('table.columnHeaderTooltip')} ${column.label}`
      },
      pagination: {
        next: `${t('table.next')}`,
        previous: `${t('table.previous')}`,
        rowsPerPage: `${t('table.rowsPerPage')}`,
        displayRows: `${t('table.displayRows')}`
      },
      toolbar: {
        search: `${t('table.search')}`,
        print: 'Imprimir',
        filterTable: `${t('table.filterTable')}`,
        viewColumns: 'Mostrar/Ocultar colunas'
      },
      filter: {
        title: `${t('table.title')}`,
        reset: `${t('table.reset')}`,
      },
      viewColumns: {
        title: `${t('table.viewColumnsTitle')}`,
      },
    },
    onChangePage: (currentPage: number) => {
      setPageNumber(currentPage);
    },
    onChangeRowsPerPage: (numberOfRows: number) => {
      setPageNumber(0)
      setItensPerPage(numberOfRows);
    },
    page: pageNumber,
    rowsPerPage: itensPerPage,
    count: totalItens,
    search: false,
    sort: false
  }

  return (
    <>
      <FiltersAprovationComponent
        commingFromHistory={true}
        filter={filtersAprov}
        setFilter={setFiltersAprov}
        refetch={mutate}
        dates={dates}
        setDates={setDates}
      />
      {(isLoading) ? (<Table />) : (
        <>
          {openModal &&
            <ModalDetailPrint
              openModal={openModal}
              setOpenModal={setOpenModal}
              selected={selected as ChecklistReportType} />
          }
          {data ?
            <StyledDiv>
              <ThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                  title={t('notifications.all.tabtitle')}
                  data={data}
                  columns={columns}
                  options={{ ...tableOptions, ...translatedTextLabels }}
                />
              </ThemeProvider>
            </StyledDiv> : <></>}
        </>
      )}
    </>
  )
}
