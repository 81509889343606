import { PowerBIEmbed } from 'powerbi-client-react';
import { useMutation } from '@tanstack/react-query';
import AttToken from '../../helpers/attToken';
import { useContext, useEffect, useMemo, useState } from 'react';
import register, { userReportsType } from '../../service/PowerBi';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../context/UserContext';
import ModalInvalid from './ModalInvalid';
import { models } from 'powerbi-client';
import { toast } from 'react-toastify';
import { Autocomplete, Skeleton, TextField } from '@mui/material';
import './styles.css';

export default function Dashboard({
  userReportsData,
  isLoadingUserReports
}: {
  userReportsData: any,
  isLoadingUserReports: boolean
}) {
  const { openModal } = useContext(UserContext);
  const servicePowerBi = useMemo(() => new register(), []);
  const [userReports, setUserReports] = useState<userReportsType[]>([]);
  const [selectedReport, setSelectedReport] = useState<userReportsType | null>(null);
  const [embedToken, setEmbedToken] = useState({
    accessToken: '',
    embedUrl: ''
  })

  const { t } = useTranslation('translation');

  useEffect(() => {
    if (userReportsData) setUserReports(userReportsData)
  }, [userReportsData])

  const { data, isLoading, mutate, isError } = useMutation({
    mutationKey: ['GET_EMBED'],
    mutationFn: async () => {
      try {
        const token = await AttToken()
        if (token) {
          const args = {
            empresa: token.userEmpresa,
            userAdm: token.username,
            workspaceId: selectedReport?.datasetWorkspaceId as string,
            reportId: selectedReport?.id as string,
            identityUser: token.username,
          }
          const response = await servicePowerBi.getEmbed(token.userToken, args)
          return response
        }
      } catch (err) {
        console.log('ops', err)
      }
    },
    useErrorBoundary: false,
    onError: () => [
      toast.error('Erro ao buscar dados!')
    ]
  })

  useEffect(() => {
    try {
      data && setEmbedToken({
        accessToken: data.accessToken,
        embedUrl: data.embedUrl[0].embedUrl,
      })
    } catch (err) {
      toast.error('Erro ao buscar dados')
    }

  }, [data])

  return (
    <div style={{width: '100%', height:'75vh'}}>
      {isLoadingUserReports ?
        <Skeleton variant='rectangular' height={50} />
        :
        <>
          <ModalInvalid openModal={openModal} />
          <Autocomplete
            id="combo-box-demo"
            sx={{ marginTop: '5px' }}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            filterSelectedOptions
            options={
              userReports
                ?.sort((a, b) => { return a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : - 1 })
                ?.map((ele: any) => ({
                  label: ele.name,
                  value: ele.id,
                }))}
            onChange={(event: any, newValue: { label: string, value: string } | null) => {
              const filteredValue = userReports.find((ele: any) => ele.id === newValue?.value)
              filteredValue && setSelectedReport(filteredValue)
              newValue?.label && mutate()
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props} key={option.value}>
                <span>{option.label}</span>
              </li>)}
            renderInput={(params) => <TextField {...params} label={t('Dashboard.Relatorios_configurados')} />}
          />
        </>
      }
      {
        selectedReport?.name?.length &&
        <>
          {isLoading ?
            <Skeleton style={{marginTop:'10px'}} variant='rectangular' height={'80%'}/>
            : isError ? <></> :
              <PowerBIEmbed
                cssClassName="powerBiStyle"
                key={userReportsData?.reportId}
                embedConfig={{
                  type: "report", // Supported types: report, dashboard, tile, visual and qna
                  id: selectedReport.id,
                  embedUrl: embedToken.embedUrl,
                  accessToken: embedToken.accessToken, // Keep as empty string, null or undefined
                  tokenType: models.TokenType.Embed, // PowerBiClient.Models.TokenType.Embed
                  settings: {
                    panes: {
                      filters: {
                        expanded: true,
                        visible: true
                      }
                    },
                  }
                }}
              />
          }
        </>
      }
    </div>
  )
}