import { useContext, useEffect, useMemo, useState } from 'react';
import { FormToFilling, ItemFormFilling, SubItemFormFilling } from './Types';
import { useLocation, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import Checklists from '../../service/Checklists';
import { Footer, Main, StyledDiv, TimeDiv } from './FormFillingStyle';
import { Accordion, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Avaliacao from './Subitens/SubitemTypes/Avaliacao/Avaliacao';
import Multiple from './Subitens/SubitemTypes/Multiple/Multiple';
import Unique from './Subitens/SubitemTypes/Unique/Unique';
import TextList from './Subitens/SubitemTypes/TextList';
import Hour from './Subitens/SubitemTypes/Hour';
import Assinatura from './Subitens/SubitemTypes/Assinatura/Assinatura';
import Email from './Subitens/SubitemTypes/Email';
import Telefone from './Subitens/SubitemTypes/Telefone';
import RG from './Subitens/SubitemTypes/RG';
import CEP from './Subitens/SubitemTypes/CEP';
import Document from './Subitens/SubitemTypes/Document';
import PlacaCarro from './Subitens/SubitemTypes/PlacaCarro';
import ShortText from './Subitens/SubitemTypes/ShortText';
import LongText from './Subitens/SubitemTypes/LongText/LongText';
import Numero from './Subitens/SubitemTypes/Numero';
import DateFormat from './Subitens/SubitemTypes/Date';
import { WebFormContext } from '../../context/WebFormContext';
import CheckIcon from '@mui/icons-material/Check';
import Select from '../Skeletons/Select';
import CheckMandatory from './CheckMandatorys';
import { toast } from 'react-toastify';
import Anonimo from '../../service/Anonimo';
import { Player } from '@lottiefiles/react-lottie-player';
import './FormFilling.css';
import TicketSelectModal from './Modals/TicketSelectModal';
import OpenTicket from './OpenTicket';
import ChamadoCardSubItem from './ChamadoCardSubItem';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DangerousIcon from '@mui/icons-material/Dangerous';
import { SubStatus, verifySubitem } from './CheckIcon';
import { memo } from 'react';
import AttToken from '../../helpers/attToken';
import { Skeleton } from '@mui/material';
import { modeloStyleType } from '../../service/checklistStyle';
import DivSkeleton from '../Skeletons/DivSkeleton';

export type props = {
  form?: FormToFilling
}

export type SubItemProps = {
  subItem: SubItemFormFilling
}

const TIMEOUTDURATION = 4000

const anonimoService = new Anonimo();

const data = Date.now()

function FormFilling({ form }: props) {
  const { setWebForm, webForm } = useContext(WebFormContext);

  const [inicio] = useState<number>(data)

  const checkListService = useMemo(() => new Checklists(), []);

  const [completed, setCompleted] = useState(false);

  const [onStartScreen, setOnStartScreen] = useState(false);

  const [block, setBlock] = useState(false);

  const [firstRender, setFirstRender] = useState(true);

  const { formId } = useParams();

  const { search } = useLocation();

  useEffect(() => {
    document.body.style.backgroundColor = 'blue'
  }, [])

  async function onClick() {
    const result = CheckMandatory(webForm);
    if (!result.success) {
      if (result.chamado) toast.warn(`Você precisa abrir um chamado no Subitem "${result.subItem}" dentro do item "${result.item}"`)
      else if (result?.texto) {
        toast.warn(result?.texto)
      }
      else if (result.item === 'chamado') toast.warn('Você precisa abrir um chamado para finalizar esse formulário!')
      else toast.warn(`Subitem "${result.subItem}" não preenchido dentro do item "${result.item}"`)
    } else {
      setBlock(true);
      await toast.promise(async () => {
        try {
          const params = decodeURIComponent(search).replace(/[?]/g, '').replace(/[ ]/g, '_').replace(/[=]/g, '/').split('&')
          await anonimoService.executaChecklistAnonimo({ ...webForm, id: formId, inicio: inicio, fim: new Date().getMilliseconds() }, params || [])
          return setCompleted(true);
        } catch (err) {
          throw new Error(`${err}`)
        }
      }, {
        pending: 'Subindo Formulário, aguarde...',
        success: 'Formulário preenchido! obrigado!',
        error: 'Erro!'
      })
      setBlock(false);
    }
  }

  const { data: anonymousForm, isLoading } = useQuery({
    queryKey: ['PreenchimentoWeb'],
    queryFn: async () => {
      const token = await AttToken();
      if ((token || window.location.pathname.includes('/preenchedor')) && formId) {
        const response = await checkListService.getChecklistForWebFilling(token || null, formId, true);
        if (response.styleOptions && response.styleOptions.enablePostMessage) {
          setOnStartScreen(true);
        }
        return response;
      }
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    if (firstRender) {
      if (form) {
        setWebForm(form);
        setFirstRender(false);
      } else if (anonymousForm) {
        setWebForm(anonymousForm.formToFilling)
        setFirstRender(false);
      }
    }
  }, [form, anonymousForm, firstRender, setWebForm]);

  useEffect(() => {
    if (completed && anonymousForm?.styleOptions?.enableLoop) {
      setTimeout(() => {
        if (anonymousForm?.styleOptions.enablePostMessage) {
          setOnStartScreen(true);
        }
        setCompleted(false)
      }, TIMEOUTDURATION);
    }
    if (completed && anonymousForm?.styleOptions?.enableRedirect && anonymousForm?.styleOptions.link) {
      setTimeout(() => {
        window.open(anonymousForm?.styleOptions.link);
      }, TIMEOUTDURATION);
    }
  }, [completed, anonymousForm])

  if (completed && anonymousForm.styleOptions?.enablePosMessage) {
    return (
      <div style={
        {
          display: 'flex',
          flexDirection: 'column',
          height: '100svh',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          padding: '10px',
        }
      }
      >
        <h1>{anonymousForm.styleOptions.messageAfter}</h1>
        {anonymousForm.styleOptions.imageAfter && (
          <img src={anonymousForm.styleOptions.imageAfter} alt="aaa" />
        )}
      </div>
    )
  }

  if (onStartScreen && !isLoading && anonymousForm.styleOptions) {
    return (
      <div style={
        {
          display: 'flex',
          flexDirection: 'column',
          height: '100svh',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          padding: '10px',
        }
      }
      >
        <h1>{anonymousForm.styleOptions.messageBefore}</h1>
        {anonymousForm.styleOptions.imageBefore && (
          <img src={anonymousForm.styleOptions.imageBefore} alt="aaa" />
        )}
        <button type="button" className="button is-success" onClick={() => setOnStartScreen(false)}>Prosseguir</button>
      </div>
    )
  }

  if (completed && !anonymousForm.styleOptions?.enablePosMessage) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', height: '100svh', alignItems: 'center', justifyContent: 'center' }}>
        <Player
          src="https://lottie.host/79da71c7-a35b-4581-be5a-cd7aa5e4f644/UGaGhP6cDm.json"
          className="player"
          autoplay
          keepLastFrame
        />
      </div>
    )
  }
  if (webForm) {
    return (
      <>
        {isLoading ? (
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
            <Select width='100%' height='60px' />
            <div style={{ marginTop: '1em', width: 'inherit', justifyContent: 'center', display: 'flex' }}>
              <Skeleton width={'300px'} height={'60px'} />
            </div>
            <div style={{ marginTop: '-1em', width: 'inherit', justifyContent: 'center', display: 'flex' }}>
              <Skeleton width='80%' height={'80px'} />
            </div>
          </div >
        ) :
          webForm ?
            <Main>
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: anonymousForm?.styleOptions?.colorHeader ? anonymousForm?.styleOptions?.colorHeader : '#2e1654',
                color: anonymousForm?.styleOptions?.fontHeader?.color ? anonymousForm?.styleOptions?.fontHeader?.color : 'white',
                fontSize: anonymousForm?.styleOptions?.fontHeader?.fontSize ? anonymousForm?.styleOptions?.fontHeader?.fontSize : '1.4em',
                fontFamily: anonymousForm?.styleOptions?.fontHeader?.fontFamily ? anonymousForm?.styleOptions?.fontHeader?.fontFamily : 'Arial',
                padding: '10px 100px',
                textAlign: 'initial',
                wordWrap: 'break-word',
                wordBreak: 'break-word',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%'
              }}>
                {anonymousForm?.styleOptions?.logo && (
                  <img
                    src={anonymousForm?.styleOptions?.logo}
                    style={{ padding: '20px', maxHeight: 250, maxWidth: 250, width: 'auto', height: 'auto', alignSelf: 'center' }}
                    alt={webForm?.nome}
                  />
                  )}
                {
                  <span>{webForm?.nome}</span>
                }

              </div>
              <StyledDiv className='hero'>
                <TimeDiv>
                  <span
                    style={{
                      fontWeight: 'bold',
                      marginLeft: '5px',
                      marginRight: '5px',
                      fontSize: '1.3em'
                    }}
                  >Início:</span>
                  <span style={{ fontSize: '1.3em' }}>{new Date(inicio).toLocaleString()}</span>
                </TimeDiv>
                <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                  {webForm?.itens?.length && webForm?.itens?.map((item, i) => (
                    <Item block={block} anonymousForm={anonymousForm?.styleOptions} response={item.subitens?.map((e) => verifySubitem(e))} item={item} indexItem={i} key={item.id} />
                  ))}
                </div>
              </StyledDiv>
              {formId &&
                <Footer
                  style={{
                    backgroundColor: anonymousForm?.styleOptions?.colorHeader ? anonymousForm?.styleOptions?.colorHeader : '#2e1654'
                  }}
                >
                  <button onClick={onClick}
                    className='button is-success' disabled={block}>
                    Enviar&nbsp;
                    <CheckIcon />
                  </button>
                  {webForm.chamadosConfig && webForm.chamadosConfig?.tipoChamado?.length > 0 && <TicketSelectModal block={block}/>}
                </Footer>}
            </Main>
            : <span>FORMULARIO NAO ENCONTRADO</span>
        }
      </>
    )
  } else return (
    <>
      : <span>FORMULARIO NAO ENCONTRADO</span>

    </>
  )
}

export default memo(FormFilling)

function ChamadoComponent({ param, indexItem }: { param: SubItemFormFilling, indexItem: number }) {
  return (
    <>
      {(param?.chamadosConfig && param?.chamadosConfig?.limiteChamado > 0 && (param?.chamados && param?.chamados?.length >= param?.chamadosConfig?.limiteChamado)) ?
        <button className='button' disabled>Este subitem atingiu o numero máximo de chamados</button> : <OpenTicket subItem={param} itemIndex={indexItem} />}
      {param?.chamados?.map((chamado, indexChamado) => (
        <ChamadoCardSubItem key={indexChamado} chamado={chamado} index={indexChamado} itemIndex={indexItem} subItemIndex={param.id} />
      ))}
    </>
  )
}
const checkChamados = (value: SubItemFormFilling) => (
  value.chamadosConfig && value.chamadosConfig?.tipoChamado.length > 0
)


function RenderSubItens(param: SubItemFormFilling, indexItem: number, onChange: () => void) {
  switch (param.tipo) {
    case 'multiple':
      return (
        <>
          <Multiple onChange={onChange} subItem={param} indexItem={indexItem} />
          {checkChamados(param) && (param?.selecionados as boolean[])?.some((ele) => ele === true) &&
            <ChamadoComponent param={param} indexItem={indexItem} />
          }
        </>
      )

    case 'unique':
      return (
        <>
          <Unique onChange={onChange} subItem={param} indexItem={indexItem} />
          {checkChamados(param) && (param?.selecionados as boolean[])?.some((ele) => ele === true) &&
            <ChamadoComponent param={param} indexItem={indexItem} />
          }
        </>
      )

    case 'avaliacao':
      return (
        <>
          <Avaliacao onChange={onChange} subItem={param} indexItem={indexItem} />
          {checkChamados(param) && (param?.nota || param?.nota === 0) &&
            <ChamadoComponent param={param} indexItem={indexItem} />
          }
        </>
      )

    case 'list':
      return (
        <>
          <TextList onChange={onChange} subItem={param} indexItem={indexItem} />
          {checkChamados(param) && (param?.selecionados as { texto: string }[])?.some((ele) => ele?.texto?.length) &&
            <ChamadoComponent param={param} indexItem={indexItem} />
          }

        </>
      )

    case 'data':
      return (
        <>
          <DateFormat onChange={onChange} subItem={param} indexItem={indexItem} />
          {checkChamados(param) && param?.ocorrencia &&
            <ChamadoComponent param={param} indexItem={indexItem} />
          }
        </>
      )

    case 'hora':
      return (
        <>
          <Hour onChange={onChange} subItem={param} indexItem={indexItem} />
          {checkChamados(param) && param?.ocorrencia &&
            <ChamadoComponent param={param} indexItem={indexItem} />
          }
        </>
      )

    case 'assinatura':
      return (
        <>
          <Assinatura onChange={onChange} subItem={param} indexItem={indexItem} />
          {checkChamados(param) && param?.assinatura &&
            <ChamadoComponent param={param} indexItem={indexItem} />
          }
        </>
      )

    case 'email':
      return (
        <>
          <Email onChange={onChange} subItem={param} indexItem={indexItem} />
          {checkChamados(param) && param?.ocorrencia &&
            <ChamadoComponent param={param} indexItem={indexItem} />
          }
        </>
      )

    case 'telefone':
      return (
        <>
          <Telefone onChange={onChange} subItem={param} indexItem={indexItem} />
          {checkChamados(param) && param?.ocorrencia &&
            <ChamadoComponent param={param} indexItem={indexItem} />
          }
        </>
      )

    case 'rg':
      return (
        <>
          <RG onChange={onChange} subItem={param} indexItem={indexItem} />
          {checkChamados(param) && param?.ocorrencia &&
            <ChamadoComponent param={param} indexItem={indexItem} />
          }
        </>
      )

    case 'cpfcnpj':
      return (
        <>
          <Document onChange={onChange} subItem={param} indexItem={indexItem} />
          {checkChamados(param) && param.ocorrencia &&
            <ChamadoComponent param={param} indexItem={indexItem} />
          }
        </>
      )

    case 'cep':
      return (
        <>
          <CEP onChange={onChange} subItem={param} indexItem={indexItem} />
          {checkChamados(param) && param.ocorrencia &&
            <ChamadoComponent param={param} indexItem={indexItem} />
          }
        </>
      )

    case 'placa':
      return (
        <>
          <PlacaCarro onChange={onChange} subItem={param} indexItem={indexItem} />
          {checkChamados(param) && param.ocorrencia &&
            <ChamadoComponent param={param} indexItem={indexItem} />
          }
        </>
      )

    case "textoCurto":
      return (
        <>
          <ShortText onChange={onChange} subItem={param} indexItem={indexItem} />
          {checkChamados(param) && param.ocorrencia &&
            <ChamadoComponent param={param} indexItem={indexItem} />
          }
        </>
      )

    case "textoLongo":
      return (
        <>
          <LongText onChange={onChange} subItem={param} indexItem={indexItem} />
          {checkChamados(param) && param.ocorrencia &&
            <ChamadoComponent param={param} indexItem={indexItem} />
          }
        </>
      )

    case "numero":
      return (
        <>
          <Numero onChange={onChange} subItem={param} indexItem={indexItem} />
          {checkChamados(param) && param.ocorrencia &&
            <ChamadoComponent param={param} indexItem={indexItem} />
          }
        </>
      )

    default:
      return (
        <span>EITA</span>
      )
  }
}

type itemProps = {
  item: ItemFormFilling,
  indexItem: number,
  response: SubStatus[],
  anonymousForm: modeloStyleType,
  block: boolean
}

function Item({ item, indexItem, response, anonymousForm, block }: itemProps) {
  const [expanded, setExpanded] = useState(false);
  const [errors, setErrors] = useState<SubStatus[]>(response);

  if (block) {
    return <DivSkeleton />
  }

  return (
    <div style={{ marginTop: '1.5em', width: '80%', alignSelf: 'center' }}>
      <Accordion style={{ overflow: 'auto', overflowX: 'hidden' }} expanded={expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
          aria-controls={item.id}
          id={item.id}
          style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: anonymousForm?.background ? anonymousForm?.background : '#2d7177', color: 'white', fontWeight: 'bold' }}
          IconButtonProps={{
            onClick: () => {
              setExpanded(!expanded)
            }
          }}
        >
          <div style={{
            color: anonymousForm?.fontBackground?.color ? anonymousForm?.fontBackground?.color : 'white',
            fontSize: anonymousForm?.fontBackground?.fontSize ? anonymousForm?.fontBackground?.fontSize : '1.4em',
            fontFamily: anonymousForm?.fontBackground?.fontFamily ? anonymousForm?.fontBackground?.fontFamily : 'Arial',
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            textAlign: 'initial',
            wordWrap: 'break-word',
            wordBreak: 'break-word',
          }}>
            {item.nome}
            {errors.some(err => err === SubStatus.naoOk) ?
              <>
                <span className="icon has-text-warning">
                  <WarningAmberIcon />
                </span>
              </> :
              <>
                <span className="icon has-text-success">
                  <CheckBoxIcon />
                </span>
              </>}
          </div>
        </AccordionSummary>
        {item?.subitens?.length && item?.subitens?.map((subItem, index) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }} key={subItem.id}>
              <SubItem indexItem={indexItem} subItem={subItem} error={errors[index]} onChange={() => {
                return setErrors(item.subitens.map((e) => verifySubitem(e)))
                }} />
            </div>
          )
        })}
      </Accordion>
    </div>
  )
}

type subItemProps = {
  subItem: SubItemFormFilling,
  indexItem: number,
  error: SubStatus,
  onChange: (value: string) => void,
}

function SubItem({ subItem, indexItem, error, onChange }: subItemProps) {
  const [expanded, setExpanded] = useState(false);

  // useEffect(() => {
  //   const response = verifySubitem(subItem)
  //   onChange(response)
  // }, [subItem])

  return (
    <div style={{ marginTop: '.5em', width: 'inherit', alignSelf: 'center', padding: '0px 20px 0px 20px' }}>
      <Accordion style={{ marginBottom: '.5em', overflow: 'auto', overflowX: 'hidden', minWidth: '100%' }} expanded={expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={subItem.itemid}
          id={subItem.itemid}
          style={{ display: 'flex', justifyContent: 'space-between', minWidth: '100% !important', fontWeight: 'bold' }}
          IconButtonProps={{
            onClick: () => {
              setExpanded(!expanded)
              const response = verifySubitem(subItem)
              onChange(response)
            }
          }}
        >
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            textAlign: 'initial',
            wordWrap: 'break-word',
            wordBreak: 'break-word',
          }}>
            <div>
              {subItem.nome}
            </div>
            <div>
              {error === SubStatus.naoOk ?
                <span className="icon has-text-danger">
                  <DangerousIcon />
                </span>
                : error === SubStatus.default ?
                  <></> :
                  <span className="icon has-text-success">
                    <CheckCircleIcon />
                  </span>
              }
            </div>
          </div>
        </AccordionSummary>
        {RenderSubItens(subItem, indexItem, () => {
          const response = verifySubitem(subItem)
          onChange(response)
        })}
      </Accordion>
    </div>
  )
}