import Header from '../components/Header/Header'
import Sidebar from '../components/Sidebar/Sidebar'
import { HeaderText } from '../StyledComponents/pages/pages';
import { useTranslation } from "react-i18next";
import ChecklistsTabs from '../components/Checklists/ChecklistsTabs';
import ChangeAlert from '../components/ChangeAlert/ChangeAlert';
import { useContext } from 'react';
import { UserContext } from '../context/UserContext';
import Footer from '../components/Footer/Footer';
import { Container, SubContainer } from './Styles';

export default function Checklists() {
  const { t } = useTranslation('translation');
  const { changed } = useContext(UserContext);

  return (
    <Container>
      <Sidebar />
      <SubContainer>
        {changed && <ChangeAlert />}
        <Header />
        <HeaderText style={{ textAlign: 'center' }}>{t('checklists.formManagement')}</HeaderText>
        <div style={{
          marginBottom: '50px',
          padding: `${window.innerWidth < 800 ? '0px 2% 0px 2%' : '0px 10% 0px 10%'}`,
        }}>
          <ChecklistsTabs />
        </div>
        <Footer />
      </SubContainer>
    </Container>
  );
}