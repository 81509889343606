import { useTranslation } from "react-i18next";
import { StyledDiv } from "../TableHelpers/TableStyle";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import { tableOptions, getMuiTheme } from "../TableHelpers/options";
import AttToken from "../../helpers/attToken";
import { useMutation } from "@tanstack/react-query";
import { ThemeProvider } from '@mui/material/styles';
import Aprovations, { FilterAprovationsType } from "../../service/Aprovations";
import { useContext, useEffect, useMemo, useState } from "react";
import ModalDetailPrint from "../Reports/ModalDetailPrint";
import { ChecklistReportType } from "../../service/Reports";
import Table from "../Skeletons/Table";
import { UserContext } from "../../context/UserContext";
import { toast } from "react-toastify";
import FiltersAprovationComponent from "./FiltersAprovationComponent";
import { Skeleton } from "@mui/material";

export type aprovationType = {
  inicio: number,
  formulario: string,
  gerencia: string,
  cliente: string,
  local: string,
  conjunto: string,
  unidade: string,
  nomeAprovador: string[],
  status: boolean

}
const aprovationService = new Aprovations();

export default function Pending() {
  const { hierarchyLabel } = useContext(UserContext);
  const { t } = useTranslation('translation');
  const [columnsViews, setColmnsViews] = useState<{ [k: string]: boolean }>();
  const [openModal, setOpenModal] = useState(false);
  const [selected, setSelected] = useState<ChecklistReportType>();
  const [pageNumber, setPageNumber] = useState(0);
  const [itensPerPage, setItensPerPage] = useState(10);
  const [totalItens, setTotalItens] = useState(10);
  const [filtersAprov, setFiltersAprov] = useState<FilterAprovationsType>({})
  const [dates, setDates] = useState({
    dataInicio: Date.now() - 2592000000,
    dataFim: Date.now(),
  })

  useEffect(() => {
    const localColumnsViews = localStorage.getItem('columnsNotif');
    if (localColumnsViews) {
      setColmnsViews(JSON.parse(localColumnsViews))
    }
  }, []);

  const { mutate, data, isLoading } = useMutation({
    mutationKey: ['GET_FILTERED'],
    mutationFn: async () => {
      const response = await toast.promise(async () => {
        const token = await AttToken();
        if (token) {
          const mocked = [];
          const response = await aprovationService.getAll(
            token,
            true,
            pageNumber + 1,
            itensPerPage,
            dates.dataInicio,
            dates.dataFim,
            filtersAprov
          )
          if (pageNumber) {
            const mockedObj = {
              scorePorcentagem: 'Mocked',
              score: 0,
              range: 'Mocked',
              user: 'Mocked',
              username: 'Mocked',
              inicio: 1704078000000,
              fim: 1716301116388,
              formulario: 'Mocked',
              cliente: 'Mocked',
              local: 'Mocked',
              conjunto: 'Mocked',
              unidade: 'Mocked',
              identificador: 'Mocked',
              subItemIdentificador: 'Mocked',
              status: 'Mocked',
              foiDelegado: 'Mocked',
              nomeAprovador: 'Mocked',
              naoConforme: 'Mocked',
              conforme: 'Mocked',
              distancia: 'Mocked',
              latitude: 'Mocked',
              longitude: 'Mocked',
              duracao: 'Mocked',
              gerencia: 'Mocked',
              nomeEmpresa: 'Mocked',
              idCliente: 'Mocked',
              equipes: 'Mocked',
              agendamento: 'Mocked',
              aprovacao: 'Mocked',
              tempoPausa: 'Mocked',
              versaoForm: 'Mocked',
              idForm: 'Mocked',
              itens: 'Mocked'
            }
            for (let i = 0; i < pageNumber * itensPerPage; i += 1) {
              mocked.push(mockedObj)
            }
          }
          if (mocked.length) {
            const newArray = [...mocked, ...response.execucoes]
            setTotalItens(response.total)
            return newArray;
          }
          setTotalItens(response.total)
          return response.execucoes
        }
      }, {
        success: 'Pronto!',
        error: 'Erro ao buscar',
        pending: 'Buscando...'
      })
      return response;
    },
  })

  useEffect(() => {
    mutate()
  }, [pageNumber, itensPerPage, mutate])

  const columns = [
    {
      name: "inicio",
      label: `${t('checklists.printConfig.Inicio')}`,
      options: {
      display:false,
        filter: false,
        sort: true,
        sortThirdClickReset: true,
      }
    },
    {
      name: 'inicio',
      label: `${t('checklists.printConfig.Inicio')}`,
      options: {
        filter: false,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value: number) => {
          const newValue = new Date(value).toLocaleString()
          return(
            <span style={{width:'150px'}}>{newValue}</span>
          )
        }
        
      ,
      }
    },
    {
      name: 'formulario',
      label: `${t('name')}`,
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: 'gerencia',
      label: `${t('Managements.manage')}`,
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: 'cliente',
      label: hierarchyLabel.cliente,
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: 'local',
      label: hierarchyLabel.local,
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "conjunto",
      label: hierarchyLabel.conjunto,
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "unidade",
      label: hierarchyLabel.unidade,
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
      }
    },
    {
      name: "user",
      label: `${t('profiles.user')}`,
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "status",
      label: 'Status',
      options: {
        customBodyRender: (value: string) => (
          value === 'pendente' && <span className="tag is-warning">{t('notifications.statusBad')}</span>
        ),

      }
    },
    {
      name: "inicio",
      label: `${t('notifications.all.show')}`,
      options: {
        display: false,
      }
    },
  ];

  const translatedTextLabels: MUIDataTableOptions = {
    onRowClick: (rowData) => {
      const value = data.find((e: ChecklistReportType) => e.inicio === Number(rowData[9]))
      if (value)
        setSelected(value)
      setOpenModal(true)
    },
    download: true,
    viewColumns: true,
    print: true,
    onViewColumnsChange: (changedColumn: string, action: string) => {
      if (action === 'remove') {
        setColmnsViews({
          ...columnsViews,
          [changedColumn]: false
        })
        localStorage.setItem('columnsNotif', JSON.stringify({
          ...columnsViews,
          [changedColumn]: false
        }))
      } else {
        setColmnsViews({
          ...columnsViews,
          [changedColumn]: true
        })
        localStorage.setItem('columnsNotif', JSON.stringify({
          ...columnsViews,
          [changedColumn]: false
        }))
      }
    },
    textLabels: {
      body: {
        noMatch: `${t('table.noMatch')}`,
        toolTip: `${t('table.toolTip')}`,
        columnHeaderTooltip: column =>
          `${t('table.columnHeaderTooltip')} ${column.label}`
      },
      pagination: {
        next: `${t('table.next')}`,
        previous: `${t('table.previous')}`,
        rowsPerPage: `${t('table.rowsPerPage')}`,
        displayRows: `${t('table.displayRows')}`
      },
      toolbar: {
        search: `${t('table.search')}`,
        print: 'Imprimir',
        filterTable: `${t('table.filterTable')}`,
        viewColumns: 'Mostrar/Ocultar colunas'
      },
      filter: {
        title: `${t('table.title')}`,
        reset: `${t('table.reset')}`,
      },
      viewColumns: {
        title: `${t('table.viewColumnsTitle')}`,
      },
    },
    onChangePage: (currentPage: number) => {
      setPageNumber(currentPage);
    },
    onChangeRowsPerPage: (numberOfRows: number) => {
      setPageNumber(0)
      setItensPerPage(numberOfRows);
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      const newData = data.map((ele: any) => {
        ele.data[0] = new Date(ele.data[0]).toLocaleString('pt-br').replace(',', '-')
        return ele
      })
      return "\uFEFF" + buildHead(columns) + buildBody(newData);
    },
    page: pageNumber,
    rowsPerPage: itensPerPage,
    count: totalItens,
    search: false,
    sort: false
  }

  return (
    <form>
      <div>
        <>
          <FiltersAprovationComponent
            commingFromHistory={false}
            filter={filtersAprov}
            setFilter={setFiltersAprov}
            refetch={mutate}
            dates={dates}
            setDates={setDates}
          />
          {(isLoading) ? (<Skeleton variant="rounded" height={600}/>) : (
            <>
              {openModal &&
                <ModalDetailPrint
                  openModal={openModal}
                  setOpenModal={setOpenModal}
                  selected={selected as ChecklistReportType} />
              }
              <StyledDiv>
                <ThemeProvider theme={getMuiTheme()}>
                  <MUIDataTable
                    title={t('notifications.all.tabtitle')}
                    data={data}
                    columns={columns}
                    options={{ ...tableOptions, ...translatedTextLabels }}
                  />
                </ThemeProvider>
              </StyledDiv>
            </>
          )}
        </>
      </div>
    </form>
  )
}

