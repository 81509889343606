import styled from "styled-components";

interface CustomProps {
  isExpanded: boolean;
}

export const RegisteredContainer = styled.div`
  display: flex;
  padding: 0;
  width: 200px;
  min-width: 140px;
  flex-direction: column;
  background-color: white;
  height: 130px;
  //border: 1px solid gray;
  //border-radius: 15px;
  margin: 5px;
  padding: 8px;
  flex-wrap: wrap;
  justify-content: space-around;
  font-size: 0.8em;
  span: {
    font-size: 12px;
  }
`;

export const FilterForm = styled.form`
  display: flex;
  margin-Top: 25px;
  //width: 90%;
  flex-direction: column;
  //margin: 2em;
  background-color: white;
  //padding: 0.5em;
  //border: 1px solid gray;
  //border-radius: 30px;
  //max-width: 100%;
  h1 {
    font-size: 1.5em;
  }
  select {
    //  border: 1px solid lightgray;
    background-color: white;
    //  height: 2.65em !important;
    color: #80808c;
  }
`;

export const ResultLimitDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  height: 60px;
  margin: 10px 20px;
  label {
    text-align: center;
    margin-right: 1em;
  }
  input {
    margin: 0;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const TagSelect = styled.div`
  border: 1px solid lightgray;
  border-radius: 3px;
  height: 40px;
  :hover {
    cursor: pointer;
  }
`;

export const InputToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 95%;
`;

export const TableStyled = styled.div`
  width: 80dvw;
  overflow: auto;
  align-self: center;
  padding: 20px;
  table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    padding: 6px;
    //table-layout:fixed;
    table-layout: fixed !important;
  }
  tbody {
    tr:hover {
      cursor: pointer;
      background-color: #48c78e;
      th {
        color: white;
      }
    }
  }
`;

export const PrintButtonContainer = styled.div`
  align-self: center;
  display: flex;
  align-items: center;
`;

export const PrintButton = styled.button`
  font-size: 25px;
  padding: 20px;
  margin-bottom: 5px;
  background-color: lightgrey;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-left: 10px;
  transition: 0.5s;
  :hover {
    cursor: pointer;
    font-size: 35px;
    background-color: ${(param) => {
      switch (param.theme) {
        case "pdf":
          return "rgb(200,10,10);";
        case "csv":
          return "rgb(117,196,119);";
        case "excel":
          return "rgb(2,108,55);";
        case "copy":
          return "rgb(57,170,232);";
        case "print":
          return "rgb(94,93,129);";
      }
    }};
  }
`;

export const StyledTd = styled.div`
  display: flex;
  padding: 5px 20px;
  align-items: center;
  justify-content: center;
  svg {
    font-size: 20px;
  }
  :hover {
    cursor: pointer;
    background-color: rgba(72, 199, 142, 1);
    font-weight: bold;
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: lightgray;
  border-radius: 15px;
  padding: 5px;
  width: 56%;
  flex-wrap: wrap;
  align-self: center;
  label {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #303030;
    input {
      border-bottom: none;
      background-color: white;
      border-radius: 10px;
    }
    input:focus {
      background-color: white;
    }
  }
  .paginate {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  a {
    font-size: 20px;
    color: #303030;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  li {
    margin: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    font-family: "Lucida Console", "Courier New", monospace;
  }
  .selectedPage {
    transition: 0.3s;
    width: 40px;
    height: 40px;
    padding: 3px 12px 0 12px;
    margin: 0 5px;
    border: 1px solid #303030;
    background-color: white;
    border-radius: 50%;
    font-weight: bold;
  }
  svg {
    align-self: center;
  }
`;

export const TableDiv = styled.div`
  //display: inline-block;
  flex-direction: column;
  margin-bottom: 1.5em;
  margin-top: 1.5em;
  background-color: white;
  //border: 1px solid gray;
  border-radius: 20px;
  padding: 1em 1em 1em 1em;
  flex-wrap: wrap;
  max-width: fit-content;
`;

export const ExpandH1 = styled.h1`
  font-weight: bold;
  margin-bottom: 35px;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  width: 85%;
  border-bottom: 1px solid grey;
  :hover {
    cursor: pointer;
  }
  svg {
    margin-left: 10px;
  }
`;

export const HiddenWrapper = styled.div<CustomProps>`
  ${(props) =>
    props.isExpanded
      ? `
    @keyframes slide-in-top {
      0% {
        transform: translateY(-50px);
        opacity: 0;
        height: 0;
        visibility: hidden;
      }
      25% {
        visibility: hidden;
        opacity: 0;
        height: 30px;
      }
      100% {
        transform: translateY(0);
        opacity: 1;
        height: 100%;
      }
    }
    width: 80%;
    align-self: center;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    animation: slide-in-top 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  `
      : `
    @keyframes slide-out-top {
      0% {
        transform: translateY(0);
        opacity: 1;
        height: 100%;
      }
      70% {
        visibility: hidden;
        opacity: 0;
        height: 30px;
      }
      100% {
        transform: translateY(-50px);
        opacity: 0;
        visibility: hidden;
        height: 0;
      }
    }
    width: 80%;
    align-self: center;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    transition: 0.5s;
    animation: slide-out-top 0.6s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  `}
`;

export const SettingsDiv = styled.div`
  display: flex;
  flex-direction: column;
  background-color: lightgray;
  padding: 15px;
  margin: 5px 0px 5px 0px;
  align-self: center;
  border-radius: 15px;
  input {
    margin-right: 5px;
  }
  span {
    color: darkgray;
  }
  -webkit-animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  @keyframes slide-bottom {
    0% {
      -webkit-transform: translateY(-100px);
      transform: translateY(-100px);
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }
`;

export const ChamadosListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  span {
    font-size: 0.7em !important;
    color: black;
    margin-left: 10px;
    align-self: center;
    font-weight: bold;
  }
  p {
    align-self: center;
    margin-left: 20px;
  }
  background-color: #e5dee5;
  padding: 10px;
  border-radius: 10px;
`;

export const TitleChamado = styled.h1`
  font-weight: bold;
  font-weight: 600;
  align-self: center;
  font-family: sans-serif;
  justify-self: center;
  text-align: center;
  font-size: 1em !important;
`;

export const LinkMapa = styled.span`
  font-weight: bold;
  color: blue;
  :hover {
    cursor: pointer;
    color: blue;
    text-decoration: underline;
  }
`;
