import { useContext, useState, useMemo, useEffect } from 'react'
import MUIDataTable, { MUIDataTableMeta, MUIDataTableOptions } from "mui-datatables";
import { EditButton, StyledDiv } from '../TableHelpers/TableStyle';
import { RiPencilFill } from 'react-icons/ri';
import { UserContext } from '../../context/UserContext';
import ChangeUserModal, { editUserType } from './ChangeUserModal';
import { useTranslation } from 'react-i18next';
import Users from '../../service/Users';
import AttToken from '../../helpers/attToken';
import { useQuery } from '@tanstack/react-query';
import Companies from '../../service/Companies';
import { ThemeProvider } from '@mui/material/styles';
import { AxiosError } from 'axios';
import { userTag } from './TagControll';
import Table from '../Skeletons/Table';
import { getMuiTheme } from "../TableHelpers/options";
import { tableOptions } from '../TableHelpers/TableOptions';

export type userBackObj = {
  aliasname: string,
  contato: string,
  dataAlteracao: number,
  dataCadastro: number,
  email: string,
  empresa: string,
  foto: string,
  gerente: string,
  id: string,
  matricula: string,
  nome: string,
  notificacao: string,
  passwdReset: string,
  perfil: string,
  status: string,
  aprovador: boolean,
  listaEquipesNomes: string[],
  tags?: [],
  listaEquipes: string[],
  gestorPA?: boolean,
  inspetorPA?: boolean,
}

export default function UserSearch() {
  const { openModal, popUp, setUserList, userList } = useContext(UserContext);
  const [selectedUser, setSelectedUser] = useState<editUserType>({} as editUserType);
  const [userTags, setUserTags] = useState<userTag[]>([])
  const { t } = useTranslation('translation');
  const usersService = useMemo(() => new Users(), []);
  const companySrvc = useMemo(() => new Companies(), []);
  const [open, setOpen] = useState<{ [i: string]: boolean }>({});

  const {
    isError,
    data,
    isLoading,
  } = useQuery({
    queryKey: ['Users'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const body = { ...token }
        const result = await usersService.getAllUsers(body);
        return result.users
      }
    },
    keepPreviousData: true,
    retry: 5,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
    onError: () => {
      console.log(AxiosError)
    }
  });

  const { data: tags, isError: isErrorTags } = useQuery({
    queryKey: ['UserTags'],
    queryFn: async () => {
      const data = await AttToken();
      if (data) {
        const tags = await companySrvc.getAllTags(data);
        return tags.tags
      }
    },
    keepPreviousData: true,
    retry: 5,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  });

  useEffect(() => {
    if (data) setUserList(data)
    if (tags) setUserTags(tags)
  }, [data, tags, setUserList])

  const handleTooltipClose = (i: number) => {
    setOpen({ ...open, [i]: false });
  };

  const handleTooltipOpen = (i: number) => {
    setOpen({ ...open, [i]: true });
  };

  const getTeamsNamesById = (userIdArray: string[]) => {
    const names: string[] = [];

    userIdArray.forEach(e => {
      names.push(`${e}\n `)
    })

    return <p style={{ whiteSpace: 'break-spaces', wordBreak: 'break-word', wordWrap: 'break-word' }}>{names}</p>
  }

  const columns = [
    {
      name: "nome",
      label: t('name'),
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "email",
      label: t('email'),
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "contato",
      label: t('tel'),
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "matricula",
      label: 'Matrícula',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "perfil",
      label: t('profile'),
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value: string) => (
          value === 'Usuario' ? t('profiles.user') : value
        ),
      }
    },
    {
      name: "listaEquipesNomes",
      label: t("team"),
      options: {
        customBodyRender: (value: string[], tableData: MUIDataTableMeta) => {
          if (value?.length)
            if (Array.isArray(value)) {
              return open[tableData.rowIndex.toString()] ? (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                  {getTeamsNamesById(value)}
                  <button
                    style={{ padding: 0 }}
                    className="button is-ghost is-small"
                    onClick={() => {
                      handleTooltipClose(tableData.rowIndex)
                    }}>
                    {t('notifications.all.hide')}
                  </button>
                </div>
              ) : (
                <button className="button is-small" onClick={() => {
                  handleTooltipOpen(tableData.rowIndex);
                }}>
                  {t('notifications.all.clickShow')}
                </button>
              );
            }
        },
        filter: false,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "status",
      label: t('status'),
      options: {
        customBodyRender: (value: string) => (
          value.toLowerCase() === 'ativo' ? <span className='tag is-primary'>{t('active')}</span> : <span className='tag is-danger'>{t('inactive')}</span>
        ),
        filter: false,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "id",
      label: t('edit'),
      options: {
        customBodyRender: (value: string) => {
          return (
            <EditButton
              className='button is-small is-light'
              onClick={() => {
                console.log(value)
                const user = data.find((elem: userBackObj) => elem.aliasname === value)
                setSelectedUser(user);
                openModal();
              }}>
              <RiPencilFill />
              <span>{t('edit')}</span>
            </EditButton>
          )
        },
        filter: false,
        sort: false,
      }
    },
  ];

  const translatedTextLabels: MUIDataTableOptions = {
    textLabels: {
      body: {
        noMatch: `${t('table.noMatch')}`,
        toolTip: `${t('table.toolTip')}`,
        columnHeaderTooltip: column =>
          `${t('table.columnHeaderTooltip')} ${column.label}`
      },
      pagination: {
        next: `${t('table.next')}`,
        previous: `${t('table.previous')}`,
        rowsPerPage: `${t('table.rowsPerPage')}`,
        displayRows: `${t('table.displayRows')}`
      },
      toolbar: {
        search: `${t('table.search')}`,
        filterTable: `${t('table.filterTable')}`
      },
      filter: {
        title: `${t('table.title')}`,
        reset: `${t('table.reset')}`
      },
      viewColumns: {
        title: `${t('table.viewColumnsTitle')}`
      }
    }
  }

  if (isError && isErrorTags) {
    return <span>Erro ao carregar dados</span>
  }

  return (
    (isLoading) ? (<Table />) : (
      <StyledDiv>
        <ThemeProvider theme={getMuiTheme()}>
          {!popUp && (
            <MUIDataTable
              title={t('users.userSearch.userList')}
              data={userList as userBackObj[]}
              columns={columns}
              options={{ ...tableOptions, ...translatedTextLabels }}
            />
          )}
        </ThemeProvider>
        {popUp && (
          <ChangeUserModal selectedUser={selectedUser} userTags={userTags} />
        )}
      </StyledDiv>
    )
  )
} 