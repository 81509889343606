import Sidebar from "../components/Sidebar/Sidebar";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { useContext } from "react";
import { UserContext } from "../context/UserContext";
import { HeaderText } from '../StyledComponents/pages/pages';
import { useTranslation } from "react-i18next";
import NotificationsTabs from "../components/Notifications/NotificationsTabs"
import ChangeAlert from "../components/ChangeAlert/ChangeAlert";
import TicketsProvider from "../context/TickestsContext";
import { Container, SubContainer } from './Styles';


export default function Notifications() {
  const { changed } = useContext(UserContext);
  const { t } = useTranslation('translation');

  return (
    <Container>
      <Sidebar />
      <SubContainer>
        {changed && <ChangeAlert />}
        <Header />
        <HeaderText style={{ textAlign: 'center' }}>{`${t('sidebar.notifications')}`}</HeaderText>
        <div style={{
          marginBottom: '50px',
          padding: `${window.innerWidth < 800 ? '0px 2% 0px 2%' : '0px 10% 0px 10%'}`,
        }}>
          <TicketsProvider>
            <NotificationsTabs />
          </TicketsProvider>
        </div>
        <Footer />
      </SubContainer>
    </Container>
  );
}
