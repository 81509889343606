import { Accordion, AccordionSummary, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useMemo, useState } from 'react';
import Places, { ConjuntoType, PlaceType } from '../../service/Places';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import 'moment/locale/pt-br';
import { AiFillCopy, AiFillDelete, AiFillTool } from 'react-icons/ai';
import { ButtonsWrapper, MainWrrapper, PlacesTitleWrapper, PlacesWrapper } from './style';
import SetAcordeon from './SetAcordeon';
import { UserContext } from '../../context/UserContext';
import { toast } from 'react-toastify';
import AttToken from '../../helpers/attToken';
import { AxiosError } from 'axios';
import { useMutation } from '@tanstack/react-query';
import RemoveModal from './Modals/RemoveModal';
import Set from '../../service/Set';
import NewModal from './Modals/NewModal';
import { FaNetworkWired } from 'react-icons/fa';
import PlaceTypeAssoci from './Modals/AssociationsModals/PlaceTypeAssoci';
import NewPlaceModal from './Modals/NewPlaceModal';
import { Label } from '../Notifications/style/SingleNotificationStyle';
import { reorderType } from './PlacesComponent';

moment.locale('pt-br');

export type placeTypeToSend = {
  id: string,
  lat: number,
  lng: number,
  localizacao: string,
  nome: string,
}

type PlacesAcordeonType = {
  place: PlaceType,
  getPlaces: Function,
  selected: string[],
  setSelected: Function,
  handleChange: Function,
  expanded: string | false,
  local: reorderType,
  setLocal: Function,
  pages: any[],
  setCurrentPage: Function
}

export default function PlacesAcordeon({
  place,
  getPlaces,
  selected,
  setSelected,
  handleChange,
  expanded,
  local,
  setLocal,
  pages,
  setCurrentPage
}
  :
  PlacesAcordeonType
) {
  const { customerId, placesList, setPlacesList, editPlace, setEditPlace, popUp, openModal, setIsEditing, } = useContext(UserContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [attSets, setAttSets] = useState(false);
  const { hierarchyLabel } = useContext(UserContext);
  const [openRemove, setOpenRemove] = useState(false);
  const [associationModal, setAssociationModal] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [expandedConjs, setExpandedConjs] = useState<boolean[]>(place.conjuntosList.map(conj => false))
  const [reorderBy, setReorderBy] = useState<string>(local[place.id] || '');


  const SetService = useMemo(() => new Set(), []);

  const PlacesServices = useMemo(() => new Places(), []);

  const { t } = useTranslation('translation');

  useEffect(() => {
    if (attSets) {
      setAttSets(false);
    }
  }, [attSets, getPlaces, selected, place.conjuntosList])

  const { mutate } = useMutation({
    mutationKey: ['removePlace'],
    mutationFn: async () => {
      toast.warn('Removendo, por favor aguarde!')
      const token = await AttToken();
      if (token) {
        await PlacesServices.deleteLocal(token, place.id, customerId)
        setSelected(selected.filter((e) => e !== place.id));
        setCurrentPage(1)
        getPlaces(customerId);
        toast.warn(`${hierarchyLabel.local} ${t('deleted')}!`);
        setOpenRemove(false)
      }
    },
    onError: () => toast.error('Erro ao deletar!')
  })

  const create = async (name: string) => {
    setIsDisabled(true)
    try {
      const token = await AttToken();
      if (token) {
        const setObj = {
          cliente: customerId,
          dataAlteracao: Date.now(),
          listaEquipe: [],
          local: place.id,
          nome: name,
          unidades: []
        }
        await SetService.newConjunto(token, setObj)
        setModalOpen(false);
        setIsDisabled(false);
        getPlaces(customerId);
        toast.success(`${hierarchyLabel.conjunto} ${t('created')}!`)
      }
    } catch (err) {
      return console.log(err);
    }
  }

  const placeToEdit = (value: string) => {
    const place = placesList && placesList.find((e) => e.id === value)
    if (place) setEditPlace({
      ...editPlace,
      id: place.id,
      lat: place.lat,
      lng: place.lng,
      localizacao: place.localizacao,
      nome: place.nome,
    })
  }

  const nameCreate = 'conjunto';
  const nameRemove = 'local';

  const buttonStyle = {
    fontSize: '1.8em',
    padding: 0,
    border: '1px solid black'
  }

  const switchSort = (value: string, a: ConjuntoType, b: ConjuntoType) => {
    switch (value) {
      case 'crescent':
        return (a.nome.toLowerCase() > b.nome.toLowerCase())
      case 'decrescent':
        return (a.nome.toLowerCase() < b.nome.toLowerCase())
      default: return (a.nome.toLowerCase() > b.nome.toLowerCase())
    }
  }

  return (
    <div style={{ marginTop: "10px" }}>
      {associationModal &&
        <PlaceTypeAssoci
          place={place}
          open={associationModal}
          setOpen={setAssociationModal}
          getPlaces={getPlaces}
        />}
      {openRemove &&
        <RemoveModal
          mutate={mutate}
          openRemove={openRemove}
          setOpenRemove={setOpenRemove}
          nameRemove={nameRemove}
        />}
      {popUp && <NewPlaceModal getPlaces={getPlaces} />}
      <Accordion expanded={expanded === place.id}>
        {modalOpen &&
          <NewModal
            isDisabled={isDisabled}
            nameCreate={nameCreate}
            create={create}
            setModalOpen={setModalOpen}
            modalOpen={modalOpen}
          />}
        <AccordionSummary
          expandIcon={<ExpandMoreIcon onClick={(isExpanded) => {
            if (expanded === place.id) {
              handleChange(!isExpanded, '')
            } else handleChange(isExpanded, place.id)
          }} />}
          aria-controls={place.id}
          id={place.id}>
          <PlacesWrapper>
            <PlacesTitleWrapper>
              <h1>{place.nome}</h1>
              <span>
                {`Criado em ${moment(place.dataCadastro).format('DD/MM/YYYY | HH:mm')} por ${place.userOwner ? place.userOwner : '--'}`}
              </span>
              <span>
                {place.localizacao}
              </span>
            </PlacesTitleWrapper>
            <ButtonsWrapper>
              <Tooltip title={`${t('teamManage.register.kindOfService')}`}>
                <span>
                  <button
                    style={buttonStyle}
                    className='button is-small edit' onClick={() => setAssociationModal(true)}>
                    <FaNetworkWired size={25} />
                  </button>
                </span>
              </Tooltip>
              <Tooltip title={`${t('edit')} ${hierarchyLabel.local}`}>
                <span>
                  <button
                    style={buttonStyle}
                    className='button is-small is-light' onClick={() => {
                      placeToEdit(place.id);
                      setIsEditing(true);
                      openModal();
                    }}>
                    <AiFillTool size={25} />
                  </button>
                </span>
              </Tooltip>
              <Tooltip title={`${t('checklists.checklists.clone')} ${hierarchyLabel.local}`}>
                <span>
                  <button
                    style={buttonStyle}
                    onClick={async () => {
                      toast.warn('Copiando, por favor aguarde!')
                      try {
                        const token = await AttToken();
                        if (token) {
                          const response = await PlacesServices.duplicateLocal(token, place.id);
                          placesList && setPlacesList([...placesList, Object.values(response.updateLocal)[0]])
                          getPlaces(place.cliente)
                          toast.success('Local duplicado!');
                        }
                      } catch (err) {
                        console.log(AxiosError)
                        toast.error('Erro ao duplicar!')
                      }
                    }}
                    className='button is-small copy'
                  >
                    <AiFillCopy size={25} />
                  </button>
                </span>
              </Tooltip>
              <Tooltip title={`${t('delete')} ${hierarchyLabel.local}`}>
                <span>
                  <button
                    style={buttonStyle}
                    className='button is-small deletebtn'
                    onClick={() => setOpenRemove(true)}
                  >
                    <AiFillDelete size={25} />
                  </button>
                </span>
              </Tooltip>
            </ButtonsWrapper>
          </PlacesWrapper>
        </AccordionSummary>
        <MainWrrapper>
          <button
            style={{ marginTop: '10px', alignSelf: 'flex-start', marginLeft: '10px' }}
            className='button is-success'
            onClick={() => {
              setModalOpen(!modalOpen)
            }}
          >
            {`${t('add')} ${hierarchyLabel.conjunto} +`}
          </button>
          <Label style={{ marginLeft: '5px', fontSize: '15px' }}>
            {hierarchyLabel.conjunto}:
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '15px' }}>
              <Label>Ordenar por:</Label>
              <select
                defaultValue={reorderBy}
                onChange={(event) => {
                  setReorderBy(event.target.value)
                  setReorderBy(event.target.value)
                  local[place.id] = event.target.value
                  setLocal(local)
                  localStorage.setItem('localOrder', JSON.stringify(local))
                }}
                style={{ margin: '0px 5px 0px 5px' }}
                className='select is-small'>
                <option value={'crescent'}>Alfabética crescente (A-Z)</option>
                <option value={'decrescent'}>Alfabética decrescente (Z-A)</option>
              </select>
            </div>
          </Label>
          {place.conjuntosList && place.conjuntosList.sort((conjuntoA, conjuntoB) => { return switchSort(reorderBy, conjuntoA, conjuntoB) ? 1 : - 1 }).map((e, i) => (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }} key={i}>
              <SetAcordeon
                local={local}
                setLocal={setLocal}
                set={e}
                placeId={place.id}
                getPlaces={getPlaces}
                selected={selected}
                conjuntosList={place.conjuntosList}
                expandedConjs={expandedConjs}
                setExpandedConjs={setExpandedConjs}
                index={i}
              />
            </div>
          ))}
        </MainWrrapper>
      </Accordion>
    </div >
  )
}
