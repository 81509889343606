import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react'
import AttToken from '../../helpers/attToken';
import Anonimo from '../../service/Anonimo';
import { ChecklistType, ItemChecklist } from '../../service/Checklists';
import { Accordion, AccordionSummary, Tooltip } from '@material-ui/core';
import { MdOutlineExpandMore } from 'react-icons/md';
import SmallTable from '../Skeletons/SmallTable';
import { Skeleton } from '@mui/material';
import { getMuiTheme } from "../TableHelpers/options";
import html2canvas from 'html2canvas';
import { FaFilePdf } from 'react-icons/fa';
import pdfMake from "pdfmake/build/pdfmake";
import { downloadExcel } from 'react-export-table-to-excel';
import { useTranslation } from 'react-i18next';
import { ChecklistReportType } from '../../service/Reports';
import { StyledDiv } from '../TableHelpers/TableStyle';
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import { ThemeProvider } from '@mui/material/styles';
import { RiFileExcel2Fill } from 'react-icons/ri';
import ModalDetailPrint from '../Reports/ModalDetailPrint';
import Pagination from '@mui/material/Pagination';
import { ChamadoType } from './Types';

function FilledAccordeon({
  modelo,
  expanded,
  setExpanded,
  handleChange
}: {
  modelo: ChecklistType,
  expanded: string | boolean,
  setExpanded: Function,
  handleChange: Function
}) {
  const { t } = useTranslation('translation');

  const mapKeys = {
    inicio: t('Reports.columns.start'),
    fim: t('Reports.columns.end'),
    duracao: `${t('preenchimento.duracao')}`,
    preenchimento: `${t('preenchimento.preenchimentoQtd')}`,
    camposOcultos: 'Chave Campo Oculto',
    camposOcultosValor: 'Valor Campo Oculto'
  }

  const [execucoes, setExecucoes] = useState<ChecklistReportType[] | null>(null);
  const [loadingEx, setLoadingEx] = useState(false);
  const [currentExecution, setCurrentExecution] = useState<ChecklistReportType | null>(null);
  const [openModal, setOpenModal] = useState(false);
  //const [expanded, setExpanded] = useState<string>('');

  const anonimoService = useMemo(() => new Anonimo(), []);

  const getExecucoes = async (formId: string) => {
    try {
      setLoadingEx(true);
      const token = await AttToken();
      if (token) {
        const execs = await anonimoService.listaHistoricoAnonimo(token, formId);
        if (execs) setExecucoes(execs);
      } else throw new Error('Error ao pegar o token')
      setLoadingEx(false)
    } catch (err) {
      setLoadingEx(false);
    }
  }

  const printToPdf = () => {
    const table = document.getElementById("print_to_pdf");
    if (table) {
      html2canvas(table)?.then(canvas => {
        const data = canvas?.toDataURL();
        const pdfExportSetting = {
          PageOrientation: 'landscape',
          content: [
            {
              pageSize: 'a4',
              compress: true,
              image: data,
              width: 400,
            }
          ]
        };
        pdfMake?.createPdf(pdfExportSetting)?.download("Relatório Axyma Forms.pdf");
      });
    }
  };

  const columns = [
    {
      name: "inicio",
      label: `${t('preenchimento.visualizar')}`,
      options: {
        display: false,
        filter: false,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "inicio",
      label: `${t('preenchimento.iniciadoEm')}`,
      options: {
        display: true,
        customBodyRender: (value: number) => {
          return (
            <span>
              {new Date(value).toLocaleString('pt-br')}
            </span>)
        },
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "fim",
      label: `${t('preenchimento.FinalizadoEm')}`,
      options: {
        display: true,
        customBodyRender: (value: number) => {
          return <span>
            {new Date(value).toLocaleString('pt-br')}
          </span>
        },
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "duracao",
      label: `${t('preenchimento.duracao')}`,
      options: {
        display: true,
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "preenchimento",
      label: `${t('preenchimento.preenchimentoQtd')}`,
      options: {
        display: true,
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "camposOcultos",
      display: true,
      label: `Chave Campo Oculto`,
      options: {
        customBodyRender: (value: { [key: string]: string }) => {
          return (
            <>
              {
                value ? Object.keys(value).map((val, i) => (
                  <span key={i}>{`${val} - `}</span>
                )) : <></>}
            </>
          )
        },
        filter: false,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "subItemIdentificador",
      label: `Subitem Identificador`,
      options: {
        filter: false,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "identificador",
      label: `Identificador`,
      options: {
        filter: false,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "camposOcultos",
      display: true,
      label: `Valor Campo Oculto`,
      options: {
        customBodyRender: (value: { [key: string]: string }) => {
          return (
            <>
              {
                value ? Object.values(value).map((val, i) => (
                  <span key={i}>{`${val} - `}</span>
                )) : <></>}
            </>
          )
        },
        filter: false,
        sort: true,
        sortThirdClickReset: true
      },
    },
    {
      name: "chamados",
      display: true,
      label: `Chamados Emitidos na Raiz`,
      options: {
        customBodyRender: (value: ChamadoType[]) => {
          return (
            <>
              {value && value.length ? (
                <span>{`${value.length} ${value.length > 1 ? 'Chamados Emitidos na Raiz' : 'Chamado Emitido na Raiz'}`}</span>
              ) : (<span>Nenhum Chamado Emitido na Raiz</span>)}
            </>
          )
        },
        filter: false,
        sort: true,
        sortThirdClickReset: true,
        sortCompare: (order: 'asc' | 'desc' | 'none') => {
          return (obj1: {data: ChamadoType[]}, obj2: {data: ChamadoType[]}) => {
            const obj1Length = obj1.data ? obj1.data.length : 0;
            const obj2Length = obj2.data ? obj2.data.length : 0;
            if (order === 'asc') {
              return obj2Length - obj1Length;
            } else {
              return obj1Length - obj2Length;
            }
          };
        }
      }
    },
    {
      name: "itens",
      display: true,
      label: `Chamados Emitidos em Subitems`,
      options: {
        customBodyRender: (value: ItemChecklist[]) => {
          if (value && value.length) {
            let chamadosAbertos = 0;
            value.forEach(e => e.subitens.forEach(sub => {
              if (sub.chamados && sub.chamados.length) { chamadosAbertos += sub.chamados.length }
            }))
            return (
              <>
                {chamadosAbertos ? (
                  <span>{`${chamadosAbertos} ${chamadosAbertos > 1 ? 'Chamados Emitidos em Subitems' : 'Chamado Emitido em Subitems'}`}</span>
                ) : (<span>Nenhum Chamado Emitido em Subitems</span>)}
              </>
            )
          } else {
            return <></>
          }
        },
        filter: false,
        sort: true,
        sortThirdClickReset: true,
        sortCompare: (order: 'asc' | 'desc' | 'none') => {
          return (obj1: {data: ItemChecklist[]}, obj2: {data: ItemChecklist[]}) => {
            let obj1Length = 0;
            let obj2Length = 0;
            obj1.data.forEach(e => e.subitens.forEach(sub => {
              if (sub.chamados && sub.chamados.length) { obj1Length += sub.chamados.length }
            }))
            obj2.data.forEach(e => e.subitens.forEach(sub => {
              if (sub.chamados && sub.chamados.length) { obj2Length += sub.chamados.length }
            }))
            if (order === 'asc') {
              return obj2Length - obj1Length;
            } else {
              return obj1Length - obj2Length;
            }
          };
        }
      },
    }
  ];

  const translatedTextLabels: MUIDataTableOptions = {
    onRowClick: (rowData) => {
      const execution = execucoes?.find(e => e.inicio === Number(rowData[0])) || null
      if (execution)
        setCurrentExecution(execution);
      setOpenModal(true);
    },
    textLabels: {
      body: {
        noMatch: `${t('table.noMatch')}`,
        toolTip: `${t('table.toolTip')}`,
        columnHeaderTooltip: column =>
          `${t('table.columnHeaderTooltip')} ${column.label}`
      },
      pagination: {
        next: `${t('table.next')}`,
        previous: `${t('table.previous')}`,
        rowsPerPage: `${t('table.rowsPerPage')}`,
        displayRows: `${t('table.displayRows')}`
      },
      toolbar: {
        search: `${t('table.search')}`,
        filterTable: `${t('table.filterTable')}`
      },
      filter: {
        title: `${t('table.title')}`,
        reset: `${t('table.reset')}`
      },
      viewColumns: {
        title: `${t('table.viewColumnsTitle')}`
      },
    },
  };

  const tableOptions: MUIDataTableOptions = {
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    customToolbar: () => {
      return (
        <>
          <Tooltip title={'Download PDF'}>
            <button
              style={{ marginTop: '5px', color: 'gray', borderRadius: '40px 40px 40px 40px' }}
              className="button is-white"
              onClick={printToPdf}>
              <FaFilePdf />
            </button>
          </Tooltip>
          <Tooltip title={'Download Excel'}>
            <button
              style={{ marginTop: '5px', color: 'gray', borderRadius: '40px 40px 40px 40px' }}
              className="button is-white"
              onClick={() => {
                const current = execucoes as ChecklistReportType[];
                const bodyArray = [] as ChecklistReportType[];
                const headerArray: string[] = [];
                Object.keys(mapKeys).forEach(key => {
                  headerArray.push(mapKeys[key as keyof typeof mapKeys])
                })
                current.forEach((elem: any) => {
                  const objToInstert: any = {}
                  Object.keys(mapKeys).forEach((key: any) => {
                    if (key === 'camposOcultos') {
                      objToInstert['camposOcultos'] = String(Object.keys(elem[key] || {})).replaceAll(',', ' | ')
                      objToInstert['camposOcultosValor'] = String(Object.values(elem[key] || {})).replaceAll(',', ' | ')
                    } else if (key === 'inicio' || key === 'fim') {
                      objToInstert[key] = new Date(Number(elem[key])).toLocaleString('pt-br').replace(',', ' ')
                    } else if (key !== 'camposOcultosValor') {
                      objToInstert[key] = elem[key]
                    }
                  }
                  )
                  bodyArray.push(objToInstert)
                })
                downloadExcel({
                  fileName: modelo.nome,
                  sheet: "react-export-table-to-excel",
                  tablePayload: {
                    header: headerArray || [''],
                    body: bodyArray as unknown as any
                  },
                });
              }}>
              <RiFileExcel2Fill />
            </button>
          </Tooltip>
        </>
      )
    },
    downloadOptions: {
      filename: modelo.nome,
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      const newData = data?.map((ele: any) => {
        ele.data[0] = new Date(ele.data[0]).toLocaleString('pt-br')
        ele.data[1] = new Date(ele.data[1]).toLocaleString('pt-br')
        ele.data[5] = Object?.keys(ele.data[6] || {})
        ele.data[6] = Object?.values(ele.data[6] || {})
        return ele
      })
      return "\uFEFF" + buildHead(columns) + buildBody(newData);
    },
    print: true,
    download: true,
    filter: false,
    filterType: 'textField',
    responsive: `vertical`,
    rowsPerPageOptions: [10, 15, Number(execucoes?.length)]
  };

  return (
    <Accordion expanded={expanded === modelo.id}>
      <AccordionSummary
        expandIcon={<MdOutlineExpandMore color="#18A689" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        IconButtonProps={{
          onClick: (isExpanded) => {
            if (expanded === modelo.id) {
              handleChange(!isExpanded, '')
            } else {
              handleChange(isExpanded, modelo.id);
              getExecucoes(modelo.id)
            }
          }
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', width: '100%' }}>
          <h1 style={{
            fontWeight: 'bold',
            fontSize: '1.4em',
            color: '#18A689',
            textAlign: 'initial',
            wordWrap: 'break-word',
            wordBreak: 'break-word',
          }}>{modelo.nome}</h1>
          <div style={{ marginTop: '5px' }}>
            <span style={{ color: '#444444' }}>
              {t('preenchimento.criadoEm')}:
            </span>
            <span style={{ fontWeight: 'bold', marginLeft: '7px', color: '#666666' }}>
              {modelo.dataCadastro ? new Date(modelo.dataCadastro).toLocaleString('pt-br') : 'Data não econtrada'}
            </span>
          </div>
        </div>
        {openModal && currentExecution && (
          <ModalDetailPrint
            numberOfTicktes={currentExecution.chamadosConfig?.limiteChamado}
            selected={currentExecution}
            openModal={openModal}
            setOpenModal={setOpenModal}
            commingFromFilled={true} />
        )}
      </AccordionSummary>
      {loadingEx || !execucoes ?
        <div style={{ padding: '2em', marginTop: '-1.5em' }}>
          <SmallTable />
        </div>
        : (
          <div style={{ width: 'inherit', display: 'flex', paddingBottom: '20px' }}>
            {!execucoes.length ? <p style={{ marginLeft: '15px' }}>Não há execuções neste formulário</p> :
              <div style={{ display: 'flex', width: '100%', padding: '0 20px 0 20px' }}>
                <StyledDiv theme={JSON.stringify(window.screen.width)}
                  id="print_to_pdf" style={{ width: '100%' }}>
                  <ThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                      title={<p style={{ wordBreak: 'break-word' }}>
                        {t('preenchimento.execucoes')}: <b>
                          {modelo.nome}
                        </b>
                      </p>
                      }
                      data={execucoes}
                      columns={columns}
                      options={{ ...tableOptions, ...translatedTextLabels }}
                    />
                  </ThemeProvider>
                </StyledDiv>
              </div>}
          </div>
        )}
      {!loadingEx && !execucoes && <h1>NENHUMA EXECUÇÃO ENCONTRADA!</h1>}
    </Accordion>
  )
}

export default function FilledForms() {
  const [search, setSearch] = useState<string>('')
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [paginated, setPaginated] = useState<ChecklistType[]>([]);
  const { t } = useTranslation('translation');
  const [expanded, setExpanded] = useState<string | boolean>(false);

  const anonimoService = useMemo(() => new Anonimo(), []);

  const { data: modelosAnonimo, isLoading: isLoadingModelos } = useQuery({
    queryKey: ['modelosAnonimo'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const response = await anonimoService.listaModelosChecklistAnonimo(token)
        return response;
      }
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  const lastPageIndex = currentPage * itemsPerPage;
  const firstPageIndex = lastPageIndex - itemsPerPage;

  useEffect(() => {
    if (modelosAnonimo) {
      const currentPageData = modelosAnonimo?.sort((a: ChecklistType, b: ChecklistType) => { return a.dataCadastro < b.dataCadastro ? 1 : -1 })
        ?.filter((e: ChecklistType) => e.nome.toLowerCase().includes(search.toLowerCase()))
      search ? setPaginated(currentPageData?.slice(0, 5)) : setPaginated(currentPageData?.slice(firstPageIndex, lastPageIndex))
    }
  }, [firstPageIndex, lastPageIndex, modelosAnonimo, search])

  let pages = [];

  for (let i = 1; i <= Math.ceil(modelosAnonimo?.length / itemsPerPage); i++) {
    pages.push(i)
  }

  const handleChange = (isExpanded: boolean, panel: string) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      {isLoadingModelos ? <>
        <Skeleton height={60} width={350} style={{ marginBottom: '-1em' }} />
        <Skeleton width={"100%"} height={50} style={{ marginBottom: '-1.8em' }} />
        <Skeleton width={"100%"} height={150} style={{ marginBottom: '-3em' }} />

      </>
        : (
          <>
            <div style={{ marginBottom: '5px' }}>
              {modelosAnonimo.length > 5
                && <Pagination
                  style={{ display: `${!pages?.length ? 'none' : ''}` }}
                  showFirstButton
                  showLastButton
                  shape="rounded"
                  variant="outlined"
                  count={pages?.length}
                  defaultPage={currentPage}
                  onChange={(eve, pageNumber) => {
                    setCurrentPage(pageNumber);

                  }}
                />}
            </div>
            <input
              style={{ marginBottom: '.5em' }}
              className='input'
              placeholder={`${t('table.search')} ${t('notifications.unread.form')}`}
              value={search}
              onChange={(e) => setSearch(e.target.value.toLowerCase())}
            />
            {paginated ? paginated?.map((modelo: ChecklistType, index: number) => (
              <div
                key={index} style={{
                  marginBottom: '10px',
                  marginTop: '10px'
                }}>
                <FilledAccordeon modelo={modelo} expanded={expanded} setExpanded={setExpanded} handleChange={handleChange} />
              </div>
            )) : <></>}
          </>
        )}
    </div>
  )
}