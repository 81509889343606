import { BiError } from "react-icons/bi";
import { NotFoundImageDiv } from "./Checklists/Modals/FormModal/FormModalStyle";
import ModalImagePreview from "./Reports/ModalImagePreview";
import { Skeleton, Tooltip } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import Reports, { ChecklistReportType } from "../service/Reports";
import AttToken from "../helpers/attToken";
import { FaEye } from "react-icons/fa";
import { FaFileDownload } from "react-icons/fa";

const ImagesCmp = ({ images, selected }: { images: string[], selected: ChecklistReportType }) => {

  const reportsService = useMemo(() => new Reports(), []);
  const [returnedImg, setReturnedImg] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState<string>('');
  const [open, setOpen] = useState(false);

  const getImages = useCallback(async () => {
    setLoading(true)
    const token = await AttToken()
    if (token && selected.itens) {
      const response = await reportsService.getImages(token, images || [])
      if (response) {
        setReturnedImg(response)
        setLoading(false)
      }
    }
  }, [images, reportsService, selected?.itens])

  useEffect(() => {
    getImages()
  }, [getImages])

  return (loading) ? (<Skeleton width={'100px'} height={'200px'} style={{ marginTop: '-1.8em' }} />) : (
    <>
      {/* <p>Imagens:</p> */}
      {open &&
        <ModalImagePreview
          image={image}
          open={open}
          setOpen={setOpen}
        />}
      <div
        style={{
          overflow: 'auto',
          display: 'flex',
          margin: 0,
          flexWrap: 'wrap',
          backgroundColor: 'white'
        }}>
        {returnedImg && returnedImg?.map((e: string, i: number) => (
          e.length > 0 ?
            <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#F5F5F5', margin: '10px' }}>
              <div style={{display: 'flex', justifyContent:'space-between'}}>
                <Tooltip title='Visualizar'>
                  <button onClick={() => {
                    setOpen(true)
                    setImage(e)
                  }} className="button is-small is-info"><FaEye /></button>
                </Tooltip>
                <Tooltip title='Download'>
                  <button onClick={() => {
                    const handleDownload = async () => {
                      const a = document.createElement("a");
                      a.href = await e;
                      a.download = "axyma.png";
                      document.body.appendChild(a);
                      a.click();
                      document.body.removeChild(a);
                    }
                    handleDownload()
                  }
                }
                    className="button is-small is-warning"><FaFileDownload /></button>
                </Tooltip>
              </div>
              <div style={{ height: '150px', width: '120px', resize: 'both', overflow: 'hidden' }} className='column is-2' key={i}>
                <img style={{ objectFit: 'cover', width: '100%', height: '100%' }} src={e} alt={`Subitem ${i}`} key={i} />
              </div>
            </div>
            : (
              <NotFoundImageDiv className='column is-2' style={{ padding: '15px' }}>
                <BiError size={50} />
                <span>{'(imagem nao encontrada)'}</span>
              </NotFoundImageDiv>
            )
        ))}
      </div >
    </>
  )
}

export default ImagesCmp;